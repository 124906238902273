import React from 'react'
import { t } from 'i18next'
import moment from 'moment'

import {
  Alert,
  Dropdown,
  Flex,
  Icon,
  Input,
  Spacing
} from '@ui'
import {
  routeUtil,
  sortUtil,
  timeUtil,
  numberUtil
} from '@app/util'

export const DataSection = ({
  details,
  isEmployeeCalendar,
  newAvailabilityOrTimeOff,
  employeeOnUnav,
  checkUserNotSelected,
  setVal,
  availableCategories,
  unavId,
  errors,
  close,
  userRoleStats,
  unavDaysInYear,
  yearVacationRemaining,
  yearVacation,
  allDayRadio,
  disabledEditation,
  displayAsDays,
  employees,
  halfDay,
  handleHalfDayChange,
  workspace
}) => {
  const employeeOptions = employees ? sortUtil.sortEmployees(Object.values(employees)
    .filter(emp => !emp.external))
    .map(emp => { return { label: emp.name, value: emp.id } })
    : []
  const handleCategoryChange = (val) => {
    if (val.value === 'newCategory') {
      routeUtil.navigate('/workspace/timeoff-categories')
      close()
      return
    }
    setVal('categoryId', val.value)
    if (val.value === 'availability' || val.value === 'unavailability') setVal('available', Boolean(val.value === 'availability'))
  }

  const workspaceContractTypes = workspace?.contractTypes ?? []

  const optsContracts = []

  const relevantContracts = Array.isArray(employeeOnUnav?.contracts)
    ? employeeOnUnav.contracts
      .filter((contract) => contract.period && (
        moment(contract.period.start).isBefore(moment(details.period.start)) || !contract.period.start
      ) && (
        moment(contract.period.end).isAfter(moment(details.period.start)) || !contract.period.end
      )
      )
    : []

  relevantContracts.forEach(con => {
    const ct = workspaceContractTypes?.find((wct) => wct.id === con.type)
    optsContracts.push({
      label: ((ct?.name || con.type || '') + (con.contractId ? (' ' + con.contractId) : '')).trim(),
      value: con.id
    })
  })

  const handleStartDateChange = (v) => {
    const period = moment(v + ' ' + moment(details.period.start).format('HH:mm'), 'YYYY-MM-DD HH:mm')
    const start = allDayRadio === 'full' ? period.startOf('day').format() : period.format()
    setVal('period', {
      start,
      end: moment(details.period.end).format()
    })
    const isMultiple = moment.duration(moment(details?.period?.end).diff(start)).asDays() > 1
    if (!isMultiple) setVal('recurrence', null)
    if (isMultiple && !details.workMinutesInclusion) setVal('workMinutesInclusion', 'shiftDays')
  }

  const handleStartTimeKeyDown = (e) => {
    const inp = document.createElement('input')
    inp.setAttribute('type', 'time')
    const timeSupported = (inp.type === 'time')

    if (e.key === 'ArrowUp' && !timeSupported) {
      setVal('period', {
        start: moment(details.period.start).add(15, 'minutes').format(),
        end: moment(details.period.end).format()
      })
    }
    if (e.key === 'ArrowDown' && !timeSupported) {
      setVal('period', {
        start: moment(details.period.start).add(-15, 'minutes').format(),
        end: moment(details.period.end).format()
      })
    }
  }

  const handleEndChange = (v) => {
    const period = moment(v + ' ' + moment(details?.period?.end).format('HH:mm'), 'YYYY-MM-DD HH:mm')
    const end = allDayRadio === 'full' ? period.endOf('day').format() : period.format()
    setVal('period', {
      start: moment(details?.period?.start).format(),
      end
    })
    const isMultiple = moment.duration(moment(end).diff(details?.period?.start)).asDays() > 1
    if (!isMultiple) setVal('recurrence', null)
    if (isMultiple && !details.workMinutesInclusion) setVal('workMinutesInclusion', 'shiftDays')
  }

  const handleEndTimeKeyDown = (e) => {
    const inp = document.createElement('input')
    inp.setAttribute('type', 'time')
    const timeSupported = (inp.type === 'time')

    if (e.key === 'ArrowUp' && !timeSupported) {
      setVal('period', {
        start: moment(details.period.start).format(),
        end: moment(details.period.end).add(15, 'minutes').format()
      })
    }
    if (e.key === 'ArrowDown' && !timeSupported) {
      setVal('period', {
        start: moment(details.period.start).format(),
        end: moment(details.period.end).add(-15, 'minutes').format()
      })
    }
  }
  const halfDayOptions = [
    {
      label: t('UNAV_FROM_START'),
      value: 'start'
    },
    {
      label: t('UNAV_TILL_END'),
      value: 'end'
    }
  ]

  const cat = availableCategories.find((c) => {
    if (details.categoryId) {
      return c.value === details.categoryId
    } else {
      return details.available
        ? c.value === 'availability'
        : c.value === 'unavailability'
    }
  })

  const contract = optsContracts.find(c => c.value === details.contractId)
  const fund = userRoleStats?.yearUnavailabilities.find(unav => unav.categoryId === details.categoryId)

  const isDefaultVacation = details.categoryId === 'dddddddddddddddddddddddd'

  const getRemainingLabel = () => {
    const x = unavDaysInYear.minutesRemaining
    const y = unavDaysInYear.minutesMaximum

    if (displayAsDays) {
      return t('REMAINING') + ': ' + t('REQUEST_UNAV_REMAINING_XY_DAYS', {
        x: numberUtil.round2decimals(x / 480).toString(),
        y: numberUtil.round2decimals(y / 480).toString()
      }).toLowerCase()
    }

    return t('UNAV_REMAINING', {
      x: timeUtil.asHour(x),
      y: timeUtil.asHour(y)
    })
  }

  return (
    <>
      <Flex>
        {!isEmployeeCalendar && (
          <>
            <Dropdown
              label={t('EMPLOYEE')}
              testid='timeoff-employee-dropdown'
              singleSelect
              searchable
              disabled={!newAvailabilityOrTimeOff}
              size={Dropdown.SIZES.FULL_WIDTH}
              type={Dropdown.TYPES.VARIABLE}
              style={Dropdown.STYLES.LIGHT}
              options={employeeOptions}
              value={employeeOnUnav ? [{ label: employeeOnUnav.name, value: employeeOnUnav.id }] : []}
              placeholder={t('SELECT_EMPLOYEE')}
              onChange={(v) => {
                setVal('userId', v.value)
                checkUserNotSelected(v.value)
              }}
              hasError={errors && errors.includes('user-not-selected')}
              errorMessage={errors && errors.includes('user-not-selected') ? t('UNAV_USER_REQUIRED') : null}
            />
            <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />
          </>
        )}
      </Flex>
      <Spacing size={Spacing.SIZES.SIZE_14} />

      <Flex>
        {/* Contract / ID */}
        <Dropdown
          singleSelect
          searchable
          size={Dropdown.SIZES.FULL_WIDTH}
          style={Dropdown.STYLES.LIGHT}
          type={Dropdown.TYPES.VARIABLE}
          options={optsContracts}
          value={contract}
          onChange={({ value }) => {
            setVal('contractId', value)
          // handleChange('contractId', value)
          // handleChange('contractType', rc?.type || null)
          }}
          placeholder={t('CHOOSE_CONTRACT')}
          label={t('AGREEMENT')}
          disabled={disabledEditation}
        />
        <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />

        {/* Type / Category */}
        <Dropdown
          size={!isEmployeeCalendar ? Dropdown.SIZES.FULL_WIDTH : Dropdown.SIZES.LARGE}
          type={Dropdown.TYPES.VARIABLE}
          style={Dropdown.STYLES.LIGHT}
          singleSelect
          disableSort
          value={cat}
          label={t('UNAV_TYPE')}
          disabled={disabledEditation
            ? true
            : (unavId && !details.categoryId)}
          onChange={handleCategoryChange}
          options={availableCategories.filter(c => {
            if (c.archived && c.value !== cat.value) return false
            return true
          })}
        />
      </Flex>
      {(userRoleStats && unavDaysInYear && unavDaysInYear.minutesMaximum !== 0) && (
        <>
          <Spacing size={Spacing.SIZES.SIZE_6} />
          <Alert
            variant={Alert.VARIANTS.SLIM}
            size={Alert.SIZES.FULL_WIDTH}
            text={displayAsDays
              ? t('REMAINING') + ': ' + t('REQUEST_UNAV_REMAINING_XY_DAYS', {
                x: numberUtil.round2decimals(fund.daysRemaining).toString(),
                y: numberUtil.round2decimals(fund.daysMaximum).toString()
              }).toLowerCase()
              : isDefaultVacation
                ? t('UNAV_REMAINING', {
                  x: timeUtil.asHour(yearVacationRemaining),
                  y: timeUtil.asHour(yearVacation)
                }) : getRemainingLabel()}
            customIco={Icon.ICONS.clock}
          />
        </>
      )}
      <Spacing size={Spacing.SIZES.SIZE_14} />
      <Flex grow={1}>
        {/* Date & Time */}
        {/* start */}
        <div style={{ width: '100%' }}>
          <Input
            size={Input.SIZES.FULL_WIDTH}
            label={t('START')}
            required
            type='date'
            onChange={handleStartDateChange}
            value={moment(details.period.start).format('YYYY-MM-DD')}
            disabled={!!disabledEditation || !!halfDay}
            hasError={!moment(details.period.start).isValid()}
          />
          {allDayRadio !== 'full' && <Spacing size={Spacing.SIZES.SIZE_6} />}
          {allDayRadio === 'part' && (
            <Input
              type='time'
              size={Input.SIZES.FULL_WIDTH}
              required
              onKeyDown={handleStartTimeKeyDown}
              onChange={(v) => setVal('period', {
                start: moment(moment(details.period.start).format('YYYY-MM-DD') + ' ' + v, 'YYYY-MM-DD HH:mm').format(),
                end: moment(details.period.end).format()
              })}
              value={moment(details.period.start).format('HH:mm')}
              disabled={!!disabledEditation}
            />
          )}

          {allDayRadio === 'half' && newAvailabilityOrTimeOff && (
            <Dropdown
              size={Dropdown.SIZES.FULL_WIDTH}
              type={Dropdown.TYPES.VARIABLE}
              style={Dropdown.STYLES.LIGHT}
              singleSelect
              disableSort
              value={halfDayOptions.find((option) => halfDay === option.value)}
              onChange={handleHalfDayChange}
              options={halfDayOptions}
            />
          )}
        </div>
        <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />
        {/* end */}
        <div style={{ width: '100%' }}>
          <Input
            size={Input.SIZES.FULL_WIDTH}
            label={t('END')}
            required
            type='date'
            onChange={handleEndChange}
            value={moment(details.period.end).subtract(1, 'ms').format('YYYY-MM-DD')} // substract - little hack to show correct END
            disabled={!!disabledEditation || !!halfDay}
            hasError={(errors && errors.includes('end-before-start')) || !moment(details.period.end).isValid()}
            errorMessage={errors && errors.includes('end-before-start') ? t('UNAV_END_BEFORE_START' + (details?.available ? '_AVAIL' : '')) : null}
          />
          {allDayRadio === 'part' && (
            <>
              <Spacing size={Spacing.SIZES.SIZE_6} />
              <Input
                size={Input.SIZES.FULL_WIDTH}
                type='time'
                required
                onKeyDown={handleEndTimeKeyDown}
                onChange={(v) => {
                  setVal('period', {
                    start: moment(details.period.start).format(),
                    end: moment(moment(details.period.end).format('YYYY-MM-DD') + ' ' + v, 'YYYY-MM-DD HH:mm').format()
                  })
                }}
                value={moment(details.period.end).format('HH:mm')}
                disabled={!!disabledEditation}
              />
            </>
          )}
        </div>
      </Flex>
    </>
  )
}
