import React, { useState } from 'react'
import { t } from 'i18next'

import {
  Button,
  Dropdown,
  Input,
  Modal,
  Text,
  Flex,
  Spacing
} from '@ui'
import { requestOrgBonusSettingsUpdate } from '@app/request'

import connect from './connect'

export const MealTicketRule = connect(({
  conditionOptions,
  contractTypeOptions,
  calculationTypeOptions,
  rules,
  getOrganization,
  organizationId,
  country,
  auth,
  setModal
}) => {
  const [form, setForm] = useState({
    contractType: null,
    calculationType: null,
    shiftDurationMinutes: null,
    condition: null,
    amount: null
  })
  const handleCreate = () => {
    requestOrgBonusSettingsUpdate({
      countryUpdateOrganization2: organizationId,
      countryUpdateCountry2: country,
      data: {
        bonuses: {
          sales: undefined,
          inventory: undefined,
          mealVouchers: {
            rules: [
              ...rules,
              {
                ...form,
                contractType: form.contractType.value,
                calculationType: form.calculationType.value,
                shiftDurationMinutes: form.shiftDurationMinutes * 60,
                condition: form.condition?.value || null,
                amount: form.amount
              }
            ]
          }
        }
      }
    }, auth).then(() => {
      getOrganization(false)
      setModal(null)
    })
  }
  return (
    <Modal
      size={form.calculationType?.value ? Modal.SIZES.L : Modal.SIZES.M}
      headerContent={
        <Text weight={Text.WEIGHTS.BOLD}>
          {t('NEW_RULE')}
        </Text>
      }
      footerContent={
        <Flex justify={Flex.POSITION.END} stretch>
          <Button
            label={t('CREATE')}
            onClick={handleCreate}
            style={Button.STYLES.CONTAINED}
          />
        </Flex>
      }
    >
      <Spacing size={Spacing.SIZES.SIZE_16}>
        <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.HORIZONTAL}>
          <Dropdown
            options={contractTypeOptions}
            style={Dropdown.STYLES.LIGHT}
            type={Dropdown.TYPES.VARIABLE}
            placeholder={t('CHOOSE')}
            singleSelect
            label={t('ORG_BONUSES_MEAL_TICKETS_EMPLOYEE')}
            onChange={(v) => setForm({ ...form, contractType: v })}
            value={form.contractType}
          />
          <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.VERTICAL} />
          <Dropdown
            options={calculationTypeOptions}
            style={Dropdown.STYLES.LIGHT}
            type={Dropdown.TYPES.VARIABLE}
            placeholder={t('CHOOSE')}
            singleSelect
            label={t('CALCULATION_TYPE')}
            onChange={(v) => {
              setForm({ ...form, calculationType: v, amount: null, shiftDurationMinutes: null, condition: null })
            }}
            value={form.calculationType}
          />
          {form.calculationType?.value === 'shiftLength' && (
            <>
              <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.VERTICAL}>
                <Flex align={Flex.POSITION.CENTER}>
                  <Text type={Text.TYPES.BODY_MEDIUM} weight={Text.WEIGHTS.BOLD}>U směny</Text>
                  <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />
                  <Dropdown
                    options={conditionOptions}
                    style={Dropdown.STYLES.LIGHT}
                    type={Dropdown.TYPES.VARIABLE}
                    placeholder={t('CHOOSE')}
                    singleSelect
                    onChange={(v) => setForm({
                      ...form,
                      condition: v
                    })}
                    value={form.condition}
                  />
                  <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.HORIZONTAL}>
                    <Input
                      onChange={(v) => setForm({
                        ...form,
                        shiftDurationMinutes: parseInt(v)
                      })}
                      value={form.shiftDurationMinutes}
                      placeholder='2'
                      type='number'
                    />
                  </Spacing>
                  <Text color={Text.COLORS.PRIMARY}>hod</Text>
                </Flex>
              </Spacing>
              <Flex align={Flex.POSITION.CENTER}>
                <Text type={Text.TYPES.BODY_MEDIUM} weight={Text.WEIGHTS.BOLD}>získá za každou odpracovanou hodinu prémii</Text>
                <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />
                <Input
                  onChange={(v) => setForm({
                    ...form,
                    amount: parseFloat(v)
                  })}
                  value={form.amount}
                  placeholder='10'
                  type='number'
                />
                <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.HORIZONTAL} />
                <Text color={Text.COLORS.PRIMARY}>Kč</Text>
              </Flex>
            </>
          )}
          {form.calculationType?.value === 'fixed' && (
            <>
              <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.VERTICAL}>
                <Flex align={Flex.POSITION.CENTER}>
                  <Text type={Text.TYPES.BODY_MEDIUM} weight={Text.WEIGHTS.BOLD}>{t('FIXED_CONDITION')}</Text>
                  <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />
                  <Input
                    onChange={(v) => setForm({
                      ...form,
                      shiftDurationMinutes: parseInt(v)
                    })}
                    value={form.shiftDurationMinutes}
                    placeholder='2'
                    type='number'
                  />
                  <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />
                  <Text color={Text.COLORS.PRIMARY}>hod</Text>
                </Flex>
              </Spacing>
              <Flex align={Flex.POSITION.CENTER}>
                <Text type={Text.TYPES.BODY_MEDIUM} weight={Text.WEIGHTS.BOLD}>{t('FIXED_REWARD')}</Text>
                <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />
                <Input
                  onChange={(v) => setForm({
                    ...form,
                    amount: parseFloat(v)
                  })}
                  value={form.amount}
                  placeholder='10'
                  type='number'
                />
                <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.HORIZONTAL} />
                <Text color={Text.COLORS.PRIMARY}>Kč</Text>
              </Flex>
            </>
          )}
        </Spacing>
      </Spacing>
    </Modal>
  )
})

MealTicketRule.propTypes = {}
MealTicketRule.defaultProps = {}
