import React from 'react'
import { t } from 'i18next'

import {
  Text,
  Flex,
  Spacing,
  Link,
  Dropdown
} from '@ui'

import connect from '../connect'

export const Cycle = connect(({
  cycleId,
  cycleGroup,
  handleChange,
  workspace
}) => {
  const createOptions = (source) => {
    return source ? source.map((cycle) => ({
      label: cycle.title + ' - ' + cycle.name,
      value: cycle.id + cycle.group,
      cycleGroup: cycle.group,
      cycleId: cycle.id
    })) : []
  }

  const cyclesWithGroups = workspace && workspace.cycles?.map((wsCycle) => wsCycle.groups.map((cycleGroup) => ({ ...wsCycle, ...cycleGroup }))).flat()
  const cycleOptions = [...createOptions(cyclesWithGroups), { label: t('CYCLE_SELECT_NO_CYCLE'), value: null }] || []
  const activeOption = cycleId ? cycleOptions.find((cycle) => cycle.cycleId === cycleId && cycle.cycleGroup === cycleGroup) : null

  return (
    <Flex direction={Flex.DIRECTION.COLUMN}>
      <Flex align={Flex.POSITION.BASELINE}>
        <Text type={Text.TYPES.BODY_MEDIUM} color={Text.COLORS.GRAY}>
          {cycleId ? t('EMPLOYEE_PROFILE_CARD_SHIFTS_TXT_USED_CYCLE') : t('EMPLOYEE_PROFILE_CARD_SHIFTS_TXT_NO_CYCLE')}
        </Text>
        <Spacing size={Spacing.SIZES.SIZE_2} type={Spacing.TYPES.HORIZONTAL} />
        <Link
          to='/workspace/cycles'
          type={Link.TYPES.ROUTER_LINK}
        >
          <Text type={Text.TYPES.BODY_MEDIUM} color={Text.COLORS.PRIMARY} text={t('CYCLE_SETTING')} />
        </Link>
      </Flex>
      <Spacing size={Spacing.SIZES.SIZE_10} type={Spacing.TYPES.VERTICAL} />
      <div style={{ width: '50%' }}>
        <Dropdown
          type={Dropdown.TYPES.VARIABLE}
          size={Dropdown.SIZES.FULL_WIDTH}
          style={Dropdown.STYLES.LIGHT}
          searchable
          singleSelect
          label={t('SET_CYCLE')}
          placeholder={t('CYCLE_SELECT_NO_CYCLE')}
          onChange={(item) => {
            if (!item.value) {
              return handleChange({ cycleId: null, cycleGroup: null })
            }
            return handleChange({ cycleId: item.cycleId, cycleGroup: item.cycleGroup })
          }}
          options={cycleOptions}
          value={activeOption}
        />
      </div>
    </Flex>
  )
})
