import setSidebar from '../action/set-sidebar'
import { miscUtil } from '@app/util'

export default (type, data = {}) => {
  return async (dispatch) => {
    if (!type) {
      // when we're closing the sidebar, we return the Intercom chat position to default
      miscUtil.intercomAdjustPosition(false)
    }

    if (!type) {
      return dispatch(setSidebar(null))
    }
    dispatch(setSidebar({
      type,
      data
    }))
  }
}
