// import React, { useEffect } from 'react'
import React from 'react'
import { Dropdown } from '@ui'
import moment from 'moment'
import { t } from 'i18next'

export const ContractTypes = ({
  workspaceContractTypes,
  employee,
  shiftStart,
  valueType,
  valueId,
  handleChange,
  disabled
}) => {
  const optsContracts = []

  const relevantContracts = Array.isArray(employee?.contracts)
    ? employee.contracts
      .filter((contract) => contract.period && (
        moment(contract.period.start).isBefore(moment(shiftStart)) || !contract.period.start
      ) && (
        moment(contract.period.end).isAfter(moment(shiftStart)) || !contract.period.end
      )
      )
    : []

  relevantContracts.forEach(con => {
    const ct = workspaceContractTypes?.find((wct) => wct.id === con.type)
    optsContracts.push({
      label: ((ct?.name || con.type || '') + (con.contractId ? (' ' + con.contractId) : '')).trim(),
      value: con.id
    })
  })

  const valueOption = valueId && optsContracts.find((o) => o.value === valueId)
  let val = valueOption || null

  if (!val) {
    const nullOption = {
      label: t('DROPDOWN_NOT_SELECTED'),
      value: null
    }
    optsContracts.push(nullOption)
    val = nullOption
  }

  if (!employee || employee.external) return null // there's no reason to display/edit contracts for external employees. they don't have a contract at our WS.
  if (employee) {
    return (
      <Dropdown
        singleSelect
        searchable
        style={Dropdown.STYLES.LIGHT}
        type={Dropdown.TYPES.VARIABLE}
        size={Dropdown.SIZES.LARGE}
        options={optsContracts}
        value={val}
        onChange={({ value }) => {
          const rc = relevantContracts.find(c => c.id === value)
          handleChange('contractId', value)
          handleChange('contractType', rc?.type || null)
        }}
        placeholder={t('CHOOSE_CONTRACT')}
        label={t('AGREEMENT')}
        disabled={disabled}
      />
    )
  }
  return null
}
