const EVENT_WARNING = `{
  name
  messageKey
  data
}`

const NOTIFICATION = `{
  id
  origin
  type
  language
  read
  resolved
  created
  message {
    title
    text
    uri
  }
}`

const EMPLOYEE_WARNING = `{
  type
  data
}`

const CANDIDATE = `{
  userId
  announce
  response {
    answer
  }
}`

const CANDIDATE_DETAIL = `{
  userId
  exchanges {
    shiftId
    shift {
      id
      period {
        start
        end
      }
    }
  }
  announce
  response {
    answer
    answers {
      shiftId
      answer
    }
  }
  request {
    created
  }
  minutesNeeded
  score
  explanations {
    type
    data
  }
  warnings {
    type
  }
}`

const DUMMY_CANDIDATE_DETAIL = `{
  userId
  userName
  external
  suitable
  minutesNeeded
  score
  explanations {
    type
    data
  }
  pauses {
    start
    duration
  }
  warnings ${EVENT_WARNING}
}`

const STANDBY_ACTIVITY_PARAMS = `
  start
  duration
  note
`

const CUSTOM_ATTRIBUTE_PARAMS = `
  attributeId
  value
`

const SHIFT_AGENDA_PARAMS = `
  id
  name
  start
  duration
  positionId
  done
  customAttributes {
    attributeId
    value
  }
`

const SHIFT_STATS_BASIC = `
  workMinutes
  overTimeTotalMinutes
`

const SHIFT_PARAMS_BASIC = `
  id
  userId
  positionId
  localityId
  idealSkill
  workspaceId
  duration
  stats {
    ${SHIFT_STATS_BASIC}
  }
  standBy
  standByActivities {
    ${STANDBY_ACTIVITY_PARAMS}
  }
  period {
    start
    end
  }
  published
  overTime {
    ordered
    unpaid
    paidTOIL
    start
    duration
  }
  plannedByPlanner
  deleted
  contractType
  contractId
  pauses {
    start
    duration
  }
  agenda {
    ${SHIFT_AGENDA_PARAMS}
  }
  customAttributes {
    ${CUSTOM_ATTRIBUTE_PARAMS}
  }
  pausesFixed
  note`

const SHIFT_PARAMS_CANDIDATES = `
  offerCandidates (includeNonSuitable: true) {
    userId
    minutesNeeded
    score
    explanations {
      type
      data
    }
    warnings ${EVENT_WARNING}
  }`

const SHIFT_PARAMS_HISTORY = `
  history {
    type
    diff
    author
    created
  }`

const SHIFT_PARAMS_ATTENDANCE = `
  attendanceApproved
  attendance {
    plannedPeriod {
      start
      end
    }
    plannedPauses {
      start
      duration
    }
  }
`

const SHIFT_BASIC = `{
  ${SHIFT_PARAMS_BASIC}
}`

const SHIFT_TEMPLATE = `{
  id
  name
  shift {
    period {
      start
      end
    }
    positionId
    localityId
    pauses {
      start
      duration
    }
    pausesFixed
    idealSkill
    note
    overTime {
      ordered
      unpaid
      paidTOIL
      start
      duration
    }
    stats {
      workMinutes
    }
    duration
    stats {
      workMinutes
    }
    standBy
    customAttributes {
      ${CUSTOM_ATTRIBUTE_PARAMS}
    }
    agenda {
      ${SHIFT_AGENDA_PARAMS}
    }
  }
}`

const SHIFT_PARAMS_STATS = `
  stats {
    date
    totalMinutes
    workMinutes
    overTimeTotalMinutes
    standByMinutes
    standByActivityMinutes
    nightMinutes
    weekDayMinutes
    weekEndMinutes
    saturdayMinutes
    sundayMinutes
    holidayMinutes
  }
`

const OFFER_ACTION_PARAMS = `
    id
    targetUsers
    result {
      replies {
        reply
        userId
        data
      }
    }
    myReply {
      reply
    }
    deadline
`
const OFFER_PARAMS_TRANSFER = `
    master {
      workspaceId
      workspaceName
      positionName
    }
    slaves {
      workspaceId
      workspaceName
      positionName
    }
`

const OFFER_PARAMS_BASIC = `
  id
  authorId
  type
  status
  announceAction {
    ${OFFER_ACTION_PARAMS}
  }
  approvalAction {
    ${OFFER_ACTION_PARAMS}
  }
  swaps {
    shiftId
    userId
  }
  transfer {
    ${OFFER_PARAMS_TRANSFER}
  }
  audience {
    options {
      filterByLocality
      filterByLocalities
      filterByPosition
      filterByPositions
      filterBySkill
      filterByWarnings
    }
    includes
    excludes
  }
  reason
  notifyEmployees
  shiftId
  created
`
const OFFER = `{
  ${OFFER_PARAMS_BASIC}
}`

const OFFER_WITH_SHIFT = `{
  ${OFFER_PARAMS_BASIC}
  shift ${SHIFT_BASIC}
}`

const AVAILABILITY = `{
  id
  userId
  workspaceId
  period {
    start
    end
  }
  available
  note
  recurrence {
    id
    type
    value
    until
  }
}`

const TIME_OFF_PARAMS = `
  id
  userId
  workspaceId
  period {
    start
    end
  }
  periodType
  categoryId
  contractId
  note
  recurrence {
    id
    type
    value
    until
  }
  attendanceApproved
  attachments {
    fileId
  }
  workDays
  workMinutes
  workMinutesBy
  totalMinutes
  requestPendingAction
`

const TIME_OFF = `{
  ${TIME_OFF_PARAMS}
}`

const PLAN = `{
  id
  status
  created
  allocatedTime
  selectedShifts
  days {
    day
  }
  planningRuleWeightOverrides {
    ruleType
    value
  }
  result
}`

const CYCLE = `{
  id
  title
  startDate
  length
  groups {
    name
    group
  }
  shifts {
    duration
    start
    positionId
    localityId
    pauses {
      start
      duration
    }
    pausesFixed
    group
    groupName
    note
    overTime {
      ordered
      unpaid
      paidTOIL
      start
      duration
    }
    standBy
    agenda {
      ${SHIFT_AGENDA_PARAMS}
    }
    customAttributes {
      ${CUSTOM_ATTRIBUTE_PARAMS}
    }
  }
}`

const LOCALITY = `{
  id
  name
  shortName
  address
  note
  created
  assigns {
    userId
  }
}`

const SHIFT_ATTRIBUTE = `{
  id
  name
  shortName
  type
  description
}`

const INVITE = `{
  id
  workspaceId
  expired
  resolved
  email
  mergeWith
  created
  lastSent
  userData
}`

const WORKSPACE_PARAMS_DETAILS = `
  address {
    name
    addressLine1
    addressLine2
    zip
    city
    state
    country
  }
  customId
  created
  timezone
  country
  language
  currentPlan ${PLAN}
  contractTypes {
    id
    name
    country
    options
    rules
  }
  unavailabilityCategories {
    id
    tag
    title
    archived
    consider
    vacation
    timeInLieu
    maxYearlyNumber
    maxDailyMinutes
    employeeCanRequest
    approveAutomatically
    displayWorkTimeAsDays
    customId
  }
  displayVacationsAsDays
  organizationId
  organization {
    id
    name
    hidden
    tokens {
      export
      apiKeys {
        id
        name
        token
      }
    }
    settings {
     defaultCurrency
     billing {
       details {
         companyName
         street
         zipCode
         city
         regNumber
         vatPayer
         billingEmail
       }
       paid
       expiration
       invoices
       trial
       trialRequest
       helpRequest
       mode
       modeDate
       uolId
       perSla
       perUser
       perPlugin
       subSla
       subUsers
       subPlugins {
          name
          count
       }
      }
      customCalendarLocking {
        lockAfterHours
        excludeWorkspaces
        silentApproval
      }
    }
  }
  settings {
    allowMultipleShiftsOnOneDay
    showOnlyCommittedInReadOnlyCalendar
    balancingPeriod {
      amount
      unit
      start
      unbalancedWorkTimeDistribution
    }
    capacityPlanning {
      warningYellowThreshold
      warningRedThreshold
    }
    plannerDefaults
    offers {
      deadlineHoursBeforeShift
      defaultAudienceOptions {
        filterByLocality
        filterByPosition
        filterBySkill
        filterByWarnings
      }
      managerApprovesRequest
      managerApprovesResult
      minHoursBeforeShift
    }
    transfers {
      managerApprovesRequest
      managerApprovesResult
      waitForOwnEmployees
      deadlineHoursBeforeShift
    }
    notifications {
        id
        name
        managers
        employees
        positions
        localities
        notifications
    }
    mfa {
        refreshPeriod {
            type
            value
        }
        allowedMethods
        allUsers
        specificUsers
    }
  }
  enforceLocalities
  cycles ${CYCLE}
  localities ${LOCALITY}
  shiftAttributes ${SHIFT_ATTRIBUTE}
  shiftTemplates ${SHIFT_TEMPLATE}
  userAttributes {
      id
      customId
      name
      type
      hidden
      options {
        values
      }
  }
  calendarLockedUntil
  calendarApprovedUntil
  calendarApprovedOn
  calendarApprovedBy
  calendarApprovedUntilLvl2
  calendarApprovedOnLvl2
  calendarApprovedByLvl2
  calendarApprovedUntilLvl3
  calendarApprovedOnLvl3
  calendarApprovedByLvl3
  plugins {
    id
    plugin
    enabled
    options
  }`

const TRANSFER_GROUPS = `
  transferGroups {
    id
    organizationId
    name
    workspaces {
      name
      id
      employees
    }
  }
  `

const POSITION_ASSIGNMENT = `
  positionId
  skill
  productionForecast
`

const CONTRACT = `{
  id
  type
  period {
    start
    end
  }
  compiled {
    options
  }
  positions {
    ${POSITION_ASSIGNMENT}
  }
  contractId
}`

const MEMBER = `{
  user {
    id
    email
    firstName
    lastName
    telephone
    name
    dummy
    lastActivity
    lastMobileAppUse
    mfa {
      enabled
    }
  }
  roles {
    terminateDate
    group
    customData {
      firstName
      lastName
      telephone
      employeeId
      kmToWork
    }
    cycleId
    cycleGroup
    contract:contractNew ${CONTRACT}
    contracts {
      id
      type
      period {
        start
        end
      }
      options
      contractId
      positions {
        ${POSITION_ASSIGNMENT}
      }
    }
    employeeId
    hidden
    calendarOrder
    terminalPIN
  }
}`

// Shifts
export const ANNOUNCE_SHIFTS = `
  mutation ($workspaceId: ID!, $ids: [ID!]) {
      shiftsPublish (
      workspaceId: $workspaceId
      ids: $ids
    )
  }
`

export const REVERT_SHIFTS = `
  mutation ($workspaceId: ID!, $period: PeriodFreeInput, $ids: [ID!]) {
    shiftsRevert(
      workspaceId: $workspaceId
      ids: $ids
      period: $period
    )
  }
`

// Bonuses
const BONUS_ITEM = `
  {
    month
    employee
    employeeId
    userId
    workspaceId
    workspaceName
    country {
      code
      id
    }
    area {
      workspaces {
        name
        id
      }
      name
      id
    }
    approvedLevel
    sales {
      amount
      currency
    }
    salesModification {
      amount
      currency
    }
    recovery {
      amount
      currency
    }
    recoveryModification {
      amount
      currency
    }
    travelPublic {
      amount
      currency
    }
    travelPublicModification {
      amount
      currency
    }
    travelKM {
      amount
      currency
    }
    travelKMModification {
      amount
      currency
    }
    szep {
      amount
      currency
    }
    szepModification {
      amount
      currency
    }
    inventory {
      currency
      amount
    }
    inventoryModification {
      amount
      currency
    }
    mealVouchers {
      currency
      amount
    }
    mealVouchersPieces {
      toOrder
      modification
      shiftActual
      shiftPlan
    }
    mealVouchersModification {
      amount
      currency
    }
    transfers {
      currency
      amount
    }
    transfersModification {
      amount
      currency
    }
    special {
      currency
      amount
    }
    reduction {
      currency
      amount
    }
    diet {
      currency
      amount
    }
    dietModification {
      amount
      currency
    }
    total {
      currency
      amount
    }
    quarterModification {
      amount
      currency
    }
    halfYearModification {
      amount
      currency
    }
    yearModification {
      amount
      currency
    }
  }
`

// Feeds
const FEED_ITEM = `
        {
            subject
            text
            pinned
            id
            created
            author
            edited
            editAuthor
            workspaceId
            attachments {
              file {
                url
                id
                filename
                size
              }
            }
            likes {
              userId
            }
            reactions {
              id
              text
              userId
              created
            }
            targets {
              userId
              readOn
              readRequire
            }
        }
`

export const FEED_ITEM_CREATE = `
    mutation (
        $subject: String!,
        $text: String!,
        $workSpaceId: ID!,
        $pinAmessage: Boolean,
        $targets: [FeedItemTargetData!],
        $attachments: [ID!]
    ) {
        feedItemCreate(workspace: $workSpaceId, data: {
            subject: $subject,
            text: $text,
            pinned: $pinAmessage,
            targets: $targets,
            attachments: $attachments,
        }) ${FEED_ITEM}
    }
`

export const FEED_ITEM_UPDATE = `
    mutation (
        $subject: String!,
        $text: String!,
        $workSpaceId: ID!,
        $id: ID!
        $pinAmessage: Boolean,
        $attachments: [ID!]
    ) {
        feedItemUpdate(workspace: $workSpaceId, id: $id, data: {
            subject: $subject,
            text: $text,
            pinned: $pinAmessage,
            attachments: $attachments,
        }) ${FEED_ITEM}
    }
`

export const FEED_ITEM_QUERY = `
    query ($userId: ID!) {
        user(id: $userId) {
            feed(limit: 100) ${FEED_ITEM}
        }
    }
`

export const FEED_ITEM_DELETE = `
    mutation ($workSpaceId: ID!, $id: ID!) {
      feedItemDelete(workspace: $workSpaceId, id: $id)
    }
`

export const FEED_ITEM_COMMENT = `
  mutation ($workSpaceId: ID!, $id: ID!, $commentInput: String!) {
    feedItemReaction(workspace: $workSpaceId, id: $id, text: $commentInput) {
      id
      userId
      text
      created
    }
  }
`

export const FEED_ITEM_LIKE = `
  mutation ($workSpaceId: ID!, $id: ID!) {
    feedItemLike(workspace: $workSpaceId, id: $id) {
      likes {
        userId
      }
    }
  }
`

export const FEED_ITEM_READ_MULTIPLE = (ids) => `
  mutation ($workSpaceId: ID!, ${ids.map((item) => `$${item.key}: ID!`)}) {
    ${ids.map((item) => `
      ${item.key}: feedItemRead(workspace: $workSpaceId, id: $${item.key}) {
        id
        targets {
          readOn
          userId
          readRequire
        }
      }
    `)}
  }
`

export const FEED_ITEM_READ = `
  mutation ($workSpaceId: ID!, $id: ID!) {
    feedItemRead(workspace: $workSpaceId, id: $id) {
      targets {
        readOn
        userId
        readRequire
      }
    }
  }
`

export const FEED_ITEM_PINT_FEED = `
  mutation ($workSpaceId: ID!, $id: ID!, $pinned: Boolean) {
    feedItemUpdate(workspace: $workSpaceId, id: $id, data: {
      pinned: $pinned
    }) {
      pinned
      id
      created
    }
  }
`

export const PRODUCTION_DEMAND_SET = `
  mutation (
    $workspaceId: ID!
    $dates: [Date!]!
    $positionId: ID
    $productionUnits: Int
  ) {
    productionDemandSet(
      workspace: $workspaceId,
      dates: $dates,
      positionId: $positionId,
      productionUnits: $productionUnits
    )
  }
`

// Cycle presets
export const CYCLE_PRESETS = `
  query ($filter: MongoFilter) {
    presets (filter: $filter) {
      name
      cycles {
        title
        length
        startDate
        shifts {
          start
          duration
          positionId
          localityId
          pauses {
            start
            duration
          }
          pausesFixed
        }
      }
      params {
        shiftLengths
        nightShifts
        weekendShifts
        startTimesCount
        isNonStop
      }
    }
  }
`

// Coworker Contact Report Query
export const COWORKER_CONTACT_REPORT = `
  query CoworkerContactReportQuery($workspace: ID!, $user: ID!, $period: PeriodInput!, $sameLocality: Boolean) {
    user(id: $user) {
      coworkerContactReport(workspace: $workspace, period: $period, sameLocality: $sameLocality) {
        coworkerContacts {
          coworker {
            id
            email
          }
          shifts {
            period {
              start
              end
            }
          }
          totalMinutes
        }
      }
    }
  }
`

export const CALENDAR_EXPORT_TOKEN = `
  query calendarExportQuery(
      $format: CalendarExportFormat!
      $workspace: ID
      $workspaces: [ID!]
      $organization: ID
      $country: ID
      $type: String
      $pdfFromXlsx: Boolean
      $period: PeriodInput
      $language: Language
      $withPositions: Boolean
      $includeUnavailabilities: Boolean
      $includeShifts: Boolean
      $isPrintColored: Boolean
      $splitByLocality: Boolean
      $showEveryoneOnWs: Boolean
      $dayNotes: Boolean
      $shiftNotes: Boolean
      $includeHidden: Boolean
      $includeSignature: Boolean
      $includeStatistics: Boolean
      $selectedLocality: ID
      $sortingEmployeesType: String,
      $attendanceApproved: Boolean,
      $contractStartPeriod: PeriodInput
    ) {
    calendarExportToken(
      options: {
        format: $format
        workspace: $workspace
        workspaces: $workspaces
        organization: $organization
        country: $country
        type: $type
        pdfFromXlsx: $pdfFromXlsx
        period: $period
        language: $language
        withPositions: $withPositions
        includeUnavailabilities: $includeUnavailabilities
        includeShifts: $includeShifts
        includeHidden: $includeHidden
        includeSignature: $includeSignature
        includeStatistics: $includeStatistics
        isPrintColored: $isPrintColored
        splitByLocality: $splitByLocality
        showEveryoneOnWs: $showEveryoneOnWs
        dayNotes: $dayNotes
        shiftNotes: $shiftNotes
        selectedLocality: $selectedLocality
        sortingEmployeesType: $sortingEmployeesType
        attendanceApproved: $attendanceApproved
        contractStartPeriod: $contractStartPeriod
      }
    ) {
      url
    }
  }
`

const ORGANIZATION_PARAMS_BASIC = `
  id
  name
  hidden
  owners
  roles {
      id
      name
      organizationId
      countries
      areas
      permissions
      assigns {
        userId
      }
  }
  settings {
    defaultCurrency
    billing {
      details {
        companyName
        street
        zipCode
        city
        regNumber
        vatPayer
        billingEmail
      }
      paid
      expiration
      invoices
      trial
      trialRequest
      helpRequest
      mode
      modeDate
      uolId
      perSla
      perUser
      perPlugin
      subSla
      subUsers
      subPlugins {
        name
        count
      }
    }
  }
`

const ORGANIZATION_COUNTRY_SHARED = `
  id
  code
  currency
  currencyRate
  bonuses {
    sales {
      minKPI
      maxKPI
      step
      positions {
        positionId
        amount
      }
      overrides {
        positionId
        minKPI
        maxKPI
        step
      }
    }
    recovery {
      overrides {
        generate
        positionId
      }
    }
    mealVouchers {
      rules {
        contractType
        condition
        shiftDurationMinutes
        amount
        calculationType
      }
    }
    inventory {
      workTimePercentage
      positions {
        positionId
        amount
      }
    }
  }
`

const ORGANIZATION_COUNTRY_PARAMS_BASIC = `
  ${ORGANIZATION_COUNTRY_SHARED}
  areas {
    id
    name
    workspaces {
      name
      id
      enforceLocalities            # required for switching between workspaces with enforceLocalities: true (in ORG)
      localities { id, shortName } # required for switching between workspaces with enforceLocalities: true (in ORG)
    }
  }
  positions {
    id
    name
    color
  }
`

const ORGANIZATION_COUNTRY_PARAMS_DETAILED = `
  ${ORGANIZATION_COUNTRY_SHARED}
  employeeCount
  hoursOfWorkPerMonth
  fullTimePercentage
  contractTypes {
    id
    name
    country
  }
  areas {
    id
    name
    employeeCount
    managers {
        id
        email
        name
      }
    workspaces {
      name
      id
      employeeCount
      customId
      managers {
        id
        email
        name
      }
      calendarLockedUntil
      calendarApprovedUntil
      calendarApprovedOn
      calendarApprovedBy
      calendarApprovedUntilLvl2
      calendarApprovedOnLvl2
      calendarApprovedByLvl2
      calendarApprovedUntilLvl3
      calendarApprovedOnLvl3
      calendarApprovedByLvl3
      enforceLocalities            # required for switching between workspaces with enforceLocalities: true (in ORG)
      localities { id, shortName } # required for switching between workspaces with enforceLocalities: true (in ORG)
    }
  }
  positions {
    id
    name
    color
  }
  externalContacts {
    email
    firstName
    lastName
    telephone
    id
  }
`

const TRANSFER_DETAILS_PARAMS = `
  shiftId
  masterShiftId
  masterShiftWorkspaceId
  name
  fromWorkspace
  toWorkspace
  date
  employeeId
  employeeFirstname
  employeeLastname
  ignoreBonus
  position {
    name
    color
  }
  attendanceApproved
  period {
    start
    end
  }
  pauses {
    duration
  }
  workedHours
  bonus {
    amount
    currency
  }
`

export default {
  OFFER_PARAMS_BASIC,
  OFFER_PARAMS_TRANSFER,
  OFFER,
  OFFER_WITH_SHIFT,
  SHIFT_BASIC,
  SHIFT_STATS_BASIC,
  SHIFT_PARAMS_BASIC,
  SHIFT_PARAMS_CANDIDATES,
  SHIFT_PARAMS_HISTORY,
  SHIFT_PARAMS_ATTENDANCE,
  SHIFT_AGENDA_PARAMS,
  SHIFT_TEMPLATE,
  SHIFT_PARAMS_STATS,
  CUSTOM_ATTRIBUTE_PARAMS,
  STANDBY_ACTIVITY_PARAMS,
  WORKSPACE_PARAMS_DETAILS,
  TRANSFER_GROUPS,
  EVENT_WARNING,
  NOTIFICATION,
  EMPLOYEE_WARNING,
  PLAN,
  AVAILABILITY,
  LOCALITY,
  TIME_OFF,
  TIME_OFF_PARAMS,
  CANDIDATE,
  CANDIDATE_DETAIL,
  DUMMY_CANDIDATE_DETAIL,
  CONTRACT,
  MEMBER,
  POSITION_ASSIGNMENT,
  INVITE,
  BONUS_ITEM,
  ORGANIZATION_PARAMS_BASIC,
  ORGANIZATION_COUNTRY_PARAMS_BASIC,
  ORGANIZATION_COUNTRY_PARAMS_DETAILED,
  TRANSFER_DETAILS_PARAMS
}
