"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const mapStateToProps = ({ workspaces, workspaceId }) => {
    const ws = workspaces && workspaceId && workspaces.find(ws => ws.id === workspaceId);
    return {
        localities: ws ? ws.localities : [],
    };
};
const mapDispatchToProps = (dispatch) => {
    return (0, redux_1.bindActionCreators)({
    // ..
    }, dispatch);
};
exports.default = (component) => {
    return (0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps)(component);
};
