import React from 'react'
import moment from 'moment'
import { t } from 'i18next'
import { Icon } from '@ui'
import './index.scss'
import connect from './connect'
import Row from '../row'
import { PERMISSIONS } from '@app/const'
import {
  numberUtil,
  miscUtil,
  timeUtil,
  calendarUtil,
  permissionsUtil,
  isDefined
} from '@app/util'
import { getOrganizationPluginActionException } from '@app/util/misc'

class Section extends React.Component {
  constructor (props) {
    super(props)
    this._isMounted = false
    this.visibleIdsRemove = this._visibleIdsRemove.bind(this)
    this.visibleIdsAdd = this._visibleIdsAdd.bind(this)
    this.getIdsInVisibleArea = this._getIdsInVisibleArea.bind(this)
    this.shouldUpdateVisibleAdd = false
    this.shouldUpdateVisibleRemove = false
    this.updateVisiblePart = this._updateVisiblePart.bind(this)
    this.state = {
      waiting: true,
      visiblePartStart: 0,
      visiblePartEnd: 500,
      visibleIds: [0, 1, 2, 3, 4],
      visibleIdsAddInterval: !props.isEmployeeCalendar && props.whichSection === 'bottom' && setInterval(this.visibleIdsAdd, 250),
      visibleIdsRemoveInterval: !props.isEmployeeCalendar && props.whichSection === 'bottom' && setInterval(this.visibleIdsRemove, 4321),
      rowHeightMinPx: 51, // this is 51 on common screen resolutions (width = 1366), but can be potentially different, for example on big screens. we update this once on componentDidMount()
      computedRowHeights: {}
    }
    this.labelWidthRem = props.labelWidthRem
    this.getDividerHTML = this._getDividerHTML.bind(this)
    this.getEmployeeText = this._getEmployeeText.bind(this)
    this.setComputedRowHeight = this._setComputedRowHeight.bind(this)

    this.enforcedLocality = miscUtil.getEnforcedLocality()
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (this.state.waiting !== nextState.waiting) {
      return true
    }

    if (this.props.cycleCalendar?.date !== nextProps.cycleCalendar?.date) {
      return true
    }
    if (miscUtil.safeStringify(this.state.visibleIds) !== miscUtil.safeStringify(nextState.visibleIds)) {
      return true
    }
    if (miscUtil.safeStringify(this.props?.cycleGroups) !== miscUtil.safeStringify(nextProps?.cycleGroups)) {
      return true
    }
    // update if rows' labelData or events change (other props of the rows are irrelevant and can even cause safeStringify to crash)
    if (miscUtil.safeStringify(this.props.rows.map(r => { return { ld: r.labelData, ev: r.events } })) !== miscUtil.safeStringify(nextProps.rows.map(r => { return { ld: r.labelData, ev: r.events } }))) {
      return true
    }

    // update if anything in store.calendar changes
    if (miscUtil.safeStringify(this.props.calendar) !== miscUtil.safeStringify(nextProps.calendar)) {
      return true
    }

    // update if store.calendarFilters change
    if (miscUtil.safeStringify(this.props.calendarFilters) !== miscUtil.safeStringify(nextProps.calendarFilters)) {
      return true
    }
    // update if sortedBy changes
    if (this.props.whichSection === 'bottom' && this.props.sortedBy !== nextProps.sortedBy) {
      return true
    }

    // update if holidays change
    // if (this.props.holidays.length !== nextProps.holidays.length) {
    //  return true
    // }
    // update if store.positions changes
    // if (miscUtil.safeStringify(this.props.positions) !== miscUtil.safeStringify(nextProps.positions)) {
    //  return true
    // }
    return false
  }

  componentDidUpdate (prevProps) {
    const rowCountChanged = (this.props.rows ? this.props.rows.length : 0) !== (prevProps.rows ? prevProps.rows.length : 0)

    // update the visible part on component update
    const el = document.querySelector('.ds-c-section.is-section-' + this.props.whichSection)
    if (el) {
      this.updateVisiblePart(el, rowCountChanged)
    }
  }

  // sets the row height pixel value in state. this is called from inside the Row components just after they finish rendering
  _setComputedRowHeight (rowId, heightPx) {
    const newHeights = Object.assign({}, this.state.computedRowHeights)
    newHeights[parseInt(rowId)] = heightPx
    this.setState(s => Object.assign({}, s, { computedRowHeights: Object.assign({}, newHeights) }))
  }

  // returns indexes of rows that are in visible area
  _getIdsInVisibleArea () {
    const { visiblePartStart, visiblePartEnd } = this.state
    const { rows } = this.props
    const ret = []
    let s = 0.0
    // loop over all the indexes potentially
    for (var i = 0; i < rows.length; i++) {
      s += this.state.computedRowHeights[i] || this.state.rowHeightMinPx
      if (s >= visiblePartStart) {
        ret.push(i)
      }
      if (s > visiblePartEnd) break // break the loop when we reach past the visible area
    }
    return ret
  }

  _visibleIdsAdd () {
    if (!this.shouldUpdateVisibleAdd) return

    const { visibleIds } = this.state
    const toAdd = []
    this.getIdsInVisibleArea().forEach(id => {
      if (!visibleIds.includes(id)) {
        toAdd.push(id)
      }
    })

    if (toAdd.length) {
      this.setState(s => Object.assign({}, s, { visibleIds: visibleIds.concat(toAdd) }))
    }
    this.shouldUpdateVisibleAdd = false
  }

  _visibleIdsRemove () {
    if (!this.shouldUpdateVisibleRemove) return

    const dragged = document.querySelector('[draggable=true]:active')
    let draggedElemIdentifier = dragged ? calendarUtil.getElementsEvtIdentifier(dragged) : null
    if (draggedElemIdentifier && draggedElemIdentifier.length) draggedElemIdentifier = draggedElemIdentifier[0]

    const { visibleIds } = this.state
    const { rows, usersMentionedInMultiselect } = this.props
    const idsInVisibleArea = this.getIdsInVisibleArea()

    const toRemove = []
    visibleIds.forEach(i => {
      if (!idsInVisibleArea.includes(i)) {
        // skip the removal of those rows that contain something that's
        // currently multiselected (that would mess up the multiselect styling)
        const r = rows[i]
        if (r && r.employee) {
          if (usersMentionedInMultiselect.includes(r.employee)) {
            return
          }
        }

        // skip the removal of rows containing events that are currently being dragged
        if (r && r.events && draggedElemIdentifier) {
          for (var j = 0; j < r.events.length; j++) {
            for (var k = 0; k < r.events[j].length; k++) {
              if (r.events[j][k].data) {
                if (calendarUtil.getEvtIdentifier(r.events[j][k].data.id, moment(r.events[j][k].data.period.start).format('YYYY-MM-DD')) === draggedElemIdentifier) {
                  return
                }
              }
            }
          }
        }

        toRemove.push(i)
      }
    })
    if (toRemove.length) {
      this.setState(s => Object.assign({}, s, { visibleIds: visibleIds.filter(i => !toRemove.includes(i)) }))
    }

    this.shouldUpdateVisibleRemove = false
  }

  componentDidMount () {
    this._isMounted = true

    // rendering this component takes a long time and blocks the UI. for example, when switching
    // to calendar from other subpage, the page freezes for several seconds before even trying to
    // display the calendar page. the UX feel is much better if we start actually rendering this
    // component asynchronously via setTimeout trick - this allows the rest of the calendar to
    // render before trying to render this section
    this.waitTimeout = setTimeout(() => {
      this.setState(s => Object.assign({}, s, { waiting: false }))
    }, 5)

    // once the component is rendered, update its scroll position/height, so that it can
    // be used to filter rendered rows
    if (!this.props.isEmployeeCalendar && this.props.whichSection === 'bottom') {
      setTimeout(() => {
        const el = document.querySelector('.ds-c-section.is-section-bottom')
        if (el) {
          this.updateVisiblePart(el)
        }
      }, 10000)
    }

    // once the component is rendered, update its typcal row height 'rowHeightMinPx' so that it can be used to filter rendered rows
    // it is 51 on common screen resolutions (width = 1366), but can be potentially different, for example on big screens. we update this once on componentDidMount()
    if (!this.props.isEmployeeCalendar && this.props.whichSection === 'bottom') {
      setTimeout(() => {
        const row = document.querySelector('.ds-c-section.is-section-bottom .ds-c-row.is-add-emp')
        if (row) {
          const rect = row.getBoundingClientRect()
          if (rect && rect.height && this._isMounted) this.setState(s => Object.assign({}, s, { rowHeightMinPx: rect.height }))
        }
      }, 2000)
    }
  }

  componentWillUnmount () {
    this._isMounted = false
    if (this.waitTimeout) clearTimeout(this.waitTimeout)
    if (this.state.visibleIdsAddInterval) clearInterval(this.state.visibleIdsAddInterval)
    if (this.state.visibleIdsRemoveInterval) clearInterval(this.state.visibleIdsRemoveInterval)
  }

  _getDividerHTML (row, idx) {
    return (
      <div
        className={`ds-c-row is-divider ${row.className || ''}`}
        key={'divider_' + idx.toString()}
      >
        {row.dividerContent}
      </div>
    )
  }

  _updateVisiblePart (elem, rowCountChanged = false) {
    const { isEmployeeCalendar, whichSection } = this.props
    if (isEmployeeCalendar) return

    // buffer above and below the visible part that is still rendered. (unit: px, original value: 390)
    // when we have 'enforcedLocality' on our WS, we can probably afford bigger buffer, because we're only displaying users on one locality
    const bufferSize = this.enforcedLocality ? 500 : 390

    const visPartStart = elem.scrollTop - bufferSize
    const visPartEnd = elem.scrollTop + elem.offsetHeight + bufferSize

    if (Math.abs(this.state.visiblePartStart - visPartStart) > 20 || Math.abs(this.state.visiblePartEnd - visPartEnd) > 20 || rowCountChanged) {
      if (this._isMounted) {
        // update visiblePartStart & visiblePartEnd in state
        if (whichSection === 'bottom') {
          this.setState(s => Object.assign({}, s, { visiblePartStart: visPartStart, visiblePartEnd: visPartEnd }))
        }
      }
      this.shouldUpdateVisibleAdd = true
      this.shouldUpdateVisibleRemove = true
    }
  }

  _getEmployeeText (ee, shiftDurationSum, empsShiftsInPeriod, workHoursFromTimeOffs) {
    const { calendar } = this.props
    const shDurationRounded = numberUtil.round2decimals(((shiftDurationSum / 60) + workHoursFromTimeOffs))
    let ret = ''

    // duration of assigned shifts
    ret += shDurationRounded.toString() + t('HOUR_SHORTEST') + ' '

    // current work hour balance
    if (!ee.external) {
      const workDays = timeUtil.getBusinessDaysInMonth(calendar.date/* , holidays */).length
      const eeWorkThisMonth = Math.round(ee.agreement * (workDays / 20))
      const missing = numberUtil.round2decimals((eeWorkThisMonth - shDurationRounded))
      ret += '(' + (missing >= 0 ? '-' : '+') + Math.abs(missing).toString() + ') \n'
    } else {
      ret += '\n'
    }

    // number of shifts in period
    ret += empsShiftsInPeriod.length.toString() + ' ' + t('SHIFTS_SHORTEST')

    return ret
  }

  render () {
    const {
      className, rows, whichSection, isEmployeeCalendar, setModal, calendar, isPreventedByPlugin,
      shiftsInPeriod, availsInPeriod, timeOffsInPeriod, dateStringsInPeriod, calendarFilters, isCycleCalendar, cycleCalendar,
      showRepeat, cycleGroups, cycleGroupsCallback, sortedBy
    } = this.props
    const { visibleIds } = this.state
    const cN = ['ds-c-section', 'is-section-' + whichSection]
    if (className) cN.push(className)

    if (this.state.waiting) return null
    const warningsHidden = calendarFilters.find(f => f.hideWarnings)

    const organizationHasOverride = (isDefined(getOrganizationPluginActionException('addEmployee')) && getOrganizationPluginActionException('addEmployee') === true)

    const hasWritePermission = permissionsUtil.canWrite(PERMISSIONS.WORKSPACE.EMPLOYEES)

    const canAddEmployee =
      (hasWritePermission && !isPreventedByPlugin('addEmployee')) ||
      organizationHasOverride

    return (
      <div
        className={cN.join(' ')}
        onScroll={(e) => {
          this.updateVisiblePart(e.target)
        }}
      >
        {rows.map((row, idx) => {
          if (row.divider) {
            // section dividers
            return this.getDividerHTML(row, idx)
          } else {
            // don't fully render the rows outside of the visible part of the section
            if (
              !isEmployeeCalendar &&
              whichSection === 'bottom' &&
              !visibleIds.includes(parseInt(idx))
            ) return <Row notVisible key={'row_' + idx.toString()} row={row} computedRowHeight={this.state.computedRowHeights[parseInt(idx)] || this.state.rowHeightMinPx} />

            // prepare the labels and filter the events & rows here.
            // for the employee rows (ee), we also prepare the event objects here ({ type: x, data: y }).
            // for non-employee rows, the event objects are prepared beforehand, in calendar-manager/WorkspaceSelect.tsx
            const ee = row.labelData.user
            if (ee) {
              row.labelData.empsShiftsInPeriod = shiftsInPeriod.filter((ev) => ev.userId === ee.id)
              row.labelData.empsAvailsInPeriod = availsInPeriod.filter((ev) => ev.userId === ee.id)
              row.labelData.empsTimeOffsInPeriod = timeOffsInPeriod.filter((ev) => ev.userId === ee.id)
              row.events = row.events.map(re => [])

              const addEvObject = (ev, type) => {
                if (!ev || !ev.period || !ev.period.start) return
                let colNum = null
                if (calendar.view === 'day') {
                  colNum = moment(ev.period.start).hour()
                } else {
                  colNum = dateStringsInPeriod.indexOf(moment(ev.period.start).format('YYYY-MM-DD'))
                }

                if (row.events[colNum]) {
                  row.events[colNum].push({
                    type: type,
                    data: ev
                  })
                }
              }

              // fill in the events (shifts, availabilities and timeOffs) for all the columns of this row
              // 1) add availabilities
              row.labelData.empsAvailsInPeriod.map((ev) => {
                addEvObject(ev, 'availability')
              })

              // 2) add timeOffs
              row.labelData.empsTimeOffsInPeriod.map((ev) => {
                const newEv = Object.assign({}, ev)
                if (warningsHidden && newEv.warnings) {
                  if (warningsHidden === 'all' || warningsHidden === true) {
                    newEv.warnings = []
                  } else if (Array.isArray(warningsHidden)) {
                    newEv.warnings = newEv.warnings.filter(w => !warningsHidden.includes(w.name))
                  }
                }
                addEvObject(newEv, 'timeOff')
              })

              // 3) add shifts
              row.labelData.empsShiftsInPeriod.map((ev) => {
                if (!ev || !ev.period || !ev.period.start) return
                let colNum = null
                if (calendar.view === 'day') {
                  colNum = moment(ev.period.start).hour()
                } else {
                  colNum = dateStringsInPeriod.indexOf(moment(ev.period.start).format('YYYY-MM-DD'))
                }
                const newEv = Object.assign({}, ev)
                if (warningsHidden && newEv.warnings) {
                  if (warningsHidden === 'all' || warningsHidden === true) {
                    newEv.warnings = []
                  } else if (Array.isArray(warningsHidden)) {
                    newEv.warnings = newEv.warnings.filter(w => !warningsHidden.includes(w.name))
                  }
                }

                if (row.events[colNum]) {
                  row.events[colNum].push({
                    type: 'shift',
                    data: newEv
                  })
                }
              })
            }

            // 4-A) apply the calendar filters: they can be any object - we just Object.apply them to the
            // events, and if they don't change them, it's a match and the event is skipped.
            const filteredEvts = []
            row.events.forEach(col => {
              filteredEvts.push(calendarUtil.getFilteredEvents(col, calendarFilters, true))
            })
            row.events = filteredEvts

            // 4-B) apply the special calendar filter 'events' - if filter.events is the same as filteredEvts, we
            // do not render this Row (only applies to bottom rows - 'events' filter cannot hide non-employee row)
            if (ee) {
              if (calendarFilters.find(fil => fil.events && miscUtil.safeStringify(fil.events) === miscUtil.safeStringify(filteredEvts))) {
                return null
              }
            }

            // prepare the label text for the employee rows
            if (ee) {
              // 1) compute the sum of shift duration of this emp
              let shiftDurationSum = 0.0
              shiftDurationSum = row.events.reduce((a, s) => {
                return a +
                s.reduce((i, e) => {
                  const upd = (e.type === 'shift' && e.data && e.data.duration ? e.data.duration : 0) +
                      (e.data.pauses && e.data.pauses.length ? -1 * e.data.pauses.filter(Boolean).reduce((j, p) => { return j + p.duration }, 0) : 0)
                  return upd >= 0 ? i + upd : 0
                }, 0)
              }, 0)

              // 2) compute the sum of work duration of the timeOffs
              const workHoursFromTimeOffs = row.events.filter(ev => ev.type === 'timeOff').reduce((a, s) => { return a + parseInt(s?.data?.workMinutes || 0) }, 0) / 60

              row.labelData.text = this.getEmployeeText(ee, shiftDurationSum, row.labelData.empsShiftsInPeriod, workHoursFromTimeOffs)
            }
            return (
              <Row
                day={row.day}
                key={'row_' + idx.toString()}
                row={row}
                idx={idx}
                labelWidthRem={this.labelWidthRem}
                computedRowHeight={this.state.computedRowHeights[parseInt(idx)] || this.state.rowHeightMinPx}
                setComputedRowHeight={this.setComputedRowHeight}
                whichSection={whichSection}
                isCycleCalendar={isCycleCalendar}
                isEmployeeCalendar={isEmployeeCalendar}
                calendar={isCycleCalendar ? cycleCalendar : calendar}
                showRepeat={showRepeat}
                cycleGroups={cycleGroups}
                cycleGroupsCallback={cycleGroupsCallback}
                sortedBy={sortedBy}
                employeeShifts={this.props.employeeShifts}
              />
            )
          }
        })}

        {/* Add Employee */}
        {(whichSection === 'bottom' && !isEmployeeCalendar && canAddEmployee)
          ? (
            <div
              className='ds-c-row is-add-emp'
              key='row_new_emp'
              onClick={() => {
                setModal('employee-add')
              }}
            >
              <div
                className='ds-c-row-label' style={{
                  width: (this.labelWidthRem).toString() + 'rem',
                  minWidth: (this.labelWidthRem).toString() + 'rem',
                  maxWidth: (this.labelWidthRem).toString() + 'rem'
                }}
              >
                <div className='ds-c-row-label-left'>
                  <Icon ico='plus' />
                </div>
                <div className='ds-c-row-label-right'>
                  {t('ADD_EMPLOYEE_SHORTER')}
                </div>
              </div>

            </div>
          )
          : null}
      </div>
    )
  }
}

export default connect(Section)
