import React from 'react'
import KSLogo from '@app/image/ks-logo.svg'
import OKBaseLogo from '@app/image/Okbase-logo.svg'
import PamicaLogo from '@app/image/pamica-logo.svg'
import AdvantoLogo from '@app/image/advanto-logo.svg'
import AlvenoLogo from '@app/image/alveno-logo.svg'
import BarisLogo from '@app/image/baris-logo.svg'
import ReservioLogo from '@app/image/reservio-logo.svg'
import DayswapsPro from '@app/image/dayswaps-logo-black-pro-modules.svg'
// import PalmAppLogo from '@app/image/palmapp-logo.svg'
import LunchDriveLogo from '@app/image/lunchdrive-logo.svg'
import PermLogo from '@app/image/perm-logo.svg'

// This sortId thing is not good 💀
// Plugins can be simply defined in the order that they have to be sorted in
const plugins = ([
  {
    id: 'calendar',
    // sortId: 1,
    isExternal: false,
    requireEnabled: true,
    color: '#0045ff',
    icon: 'calendar',
    web: 'https://dayswaps.com/modul/plan',
    settings: '/settings/employee-preferences',
    help: 'https://help.dayswaps.com/category/45-provoz'
  },
  {
    id: 'offers',
    // sortId: 2,
    isExternal: false,
    color: '#ff8a00',
    icon: 'offer',
    web: 'https://dayswaps.com/modul/offer',
    settings: '/settings/contest-settings',
    help: 'https://help.dayswaps.com/article/121-shifts-offering'
  },
  {
    id: 'attendance',
    // sortId: 3,
    isExternal: false,
    color: '#1ac9a3',
    icon: 'stats',
    web: 'https://dayswaps.com/modul/attendance',
    settings: '/backoffice/attendance',
    help: 'https://help.dayswaps.com/category/251-dochazka'
  },
  {
    id: 'laborlaw',
    // sortId: 4,
    isExternal: false,
    color: '#ff495c',
    icon: 'law',
    web: 'https://dayswaps.com/modul/law',
    settings: '/settings/employee-preferences',
    help: 'https://help.dayswaps.com/article/160-labour-law-warning'
  },
  {
    id: 'capacityplanning',
    // sortId: 5,
    isExternal: false,
    color: '#7a87ff',
    icon: 'capacity',
    web: 'https://dayswaps.com/modul/capacity',
    settings: '/settings/capacities',
    help: 'https://help.dayswaps.com/article/172-capacity-setting-per-position'
  },
  {
    id: 'communication',
    // sortId: 6,
    isExternal: false,
    color: '#4cadf4',
    icon: 'comment',
    web: 'https://dayswaps.com/modul/communication',
    help: 'https://help.dayswaps.com/collection/25-komunikace'
  },
  {
    id: 'localities',
    // sortId: 8,
    isExternal: false,
    color: '#396ba7',
    icon: 'location',
    web: 'https://dayswaps.com/modul/locality',
    settings: '/workspace/localities',
    help: 'https://help.dayswaps.com/article/180-locality-creation'
  },
  {
    id: 'agenda',
    // sortId: 9,
    isExternal: false,
    color: '#396ba7',
    icon: 'capacity',
    web: 'https://dayswaps.com/modul/agenda'
  },
  {
    id: 'reports',
    // sortId: 10,
    isExternal: false,
    color: '#a4c910',
    icon: 'report',
    web: 'https://dayswaps.com/modul/report',
    settings: '/backoffice/reports',
    help: 'https://help.dayswaps.com/category/162-jak-muzete-planovat'
  },
  {
    id: 'standby',
    // sortId: 11,
    isExternal: false,
    color: '#dc0068',
    icon: 'standby-plugin',
    web: 'https://dayswaps.com/modul/emergency',
    settings: '/workspace/attributes',
    help: 'https://help.dayswaps.com/article/186-standby-shift-creation'
  },
  {
    id: 'chat',
    // sortId: 7,
    isExternal: false,
    onlyOnRequest: true,
    color: '#ff61d3',
    icon: 'chat'
  },
  {
    id: 'terminal',
    // sortId: 12,
    isExternal: false,
    color: '#557BDC',
    icon: 'history',
    web: 'https://dayswaps.com/',
    settings: '/backoffice/terminals',
    help: 'https://help.dayswaps.com/'
  },
  {
    id: 'apikeys',
    // sortId: 13,
    isExternal: false,
    color: '#AB4F20',
    icon: 'api',
    web: 'https://dayswaps.com/',
    settings: '/workspace/api-keys',
    help: 'https://help.dayswaps.com/'
  },
  (['localhost', 'app.local.dayswaps.top', 'app.dev.dayswaps.top'].includes(window.location.hostname) ? ({ // this is still in development, so it shouldn't be displayed in PROD or STAGE. however we want to see it on DEV/LOCAL for internal testing.
    id: 'assistant',
    sortId: 14,
    isExternal: false,
    onlyOnRequest: true,
    color: '#834AA6',
    icon: 'assistant'
    // web: 'https://dayswaps.com/pro',
    // settings: '/organization',
    // help: 'https://help.dayswaps.com/'
  }) : null),
  {
    id: 'dayswapspro',
    // sortId: 14,
    isExternal: false,
    onlyOnRequest: true,
    color: '#AB4F20',
    logo: <DayswapsPro />,
    web: 'https://dayswaps.com/pro',
    settings: '/organization',
    help: 'https://help.dayswaps.com/'
  },
  {
    id: 'mfa',
    // sortId: 15,
    isExternal: false,
    onlyOnRequest: true,
    color: '#AB4F20',
    icon: 'monitor',
    settings: '/workspace'
  },
  // EXTERNAL
  {
    id: 'okbase',
    // sortId: 16,
    isExternal: true,
    onlyOnRequest: true,
    color: 'grey',
    logo: <OKBaseLogo />,
    preventsFunctions: ['addEmployee', 'editJobPositions', 'assignJobPositions', 'editEmployeeContracts', 'editEmployeeDataBirthDate', 'pluginActivateDeactivate']
  },
  {
    id: 'ksportal',
    // sortId: 17,
    isExternal: true,
    onlyOnRequest: true,
    color: 'grey',
    logo: <KSLogo />,
    preventsFunctions: [
      'addEmployee', 'editJobPositions', 'assignJobPositions', 'editEmployeeContracts', 'editEmployeeDataName', 'editEmployeeDataAddress', 'editEmployeeDataBirthDate',
      'editEmployeeDataEmployeeId', 'editEmployeeDataVacationDays', 'editEmployeeRoleType', 'terminateEmployee', 'pluginActivateDeactivate', 'editUnavailabilityCategories',
      'editWorkspaceName', 'editWorkspaceBalancingPeriod', 'editWorkspaceOffers'
    ]
  },
  {
    id: 'pamica',
    // sortId: 18,
    isExternal: true,
    onlyOnRequest: true,
    color: '#3F3F3F',
    logo: <PamicaLogo />
  },
  {
    id: 'advanto',
    // sortId: 19,
    isExternal: true,
    onlyOnRequest: true,
    color: '#56A6D7',
    logo: <AdvantoLogo />
  },
  /* {
    sortId: 19,
    id: 'palmapp',
    isExternal: true,
    onlyOnRequest: true,
    color: '#7DD697',
    logo: <PalmAppLogo />
  }, */
  {
    id: 'lunchdrive',
    // sortId: 20,
    isExternal: true,
    onlyOnRequest: true,
    color: '#437FB5',
    logo: <LunchDriveLogo />
  },
  {
    id: 'perm',
    // sortId: 21,
    isExternal: true,
    onlyOnRequest: true,
    color: '#FA6400',
    logo: <PermLogo />
  },
  {
    id: 'alveno',
    // sortId: 22,
    isExternal: true,
    onlyOnRequest: true,
    color: '#485364',
    logo: <AlvenoLogo />,
    preventsFunctions: ['pluginActivateDeactivate', 'editUnavailabilityCategories', 'editWorkspaceOffers', 'editJobPositions', 'assignJobPositions']
  },
  {
    id: 'baris',
    // sortId: 23,
    isExternal: true,
    onlyOnRequest: true,
    color: '#981C43',
    logo: <BarisLogo />
  },
  {
    id: 'reservio',
    // sortId: 24,
    isExternal: true,
    onlyOnRequest: true,
    color: '#DC3542',
    logo: <ReservioLogo />
  }
].filter(Boolean)).map((item, index) => {
  item.sortId = index + 1
  return item
})

const pluginNames = {}
plugins.forEach((item) => {
  pluginNames[item.id.toUpperCase()] = item.id
})
export default {
  SUPPORTED_PLUGINS: plugins,
  NAMES: pluginNames
}
