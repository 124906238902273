import React, { useState } from 'react'
import { t } from 'i18next'
import connect from './connect'

import {
  Button,
  Input,
  Select,
  Dropdown,
  Text,
  Modal,
  Flex,
  Spacing
} from '@ui'
import { toBoolean } from '@app/util'
import { requestWorkspaceUpdate } from '@app/request'
import { TimeInput } from '@app/component/ui/input/time-input/time-input'

const UnavailabilityAdd = ({
  workspaceId,
  ws,
  auth,
  setModal,
  loadWorkspaceDetail
}) => {
  const [form, setForm] = useState({
    title: null,
    tag: null,
    consider: true,
    duration: true,
    maxDailyMinutes: null,
    vacation: false,
    maxYearlyNumber: 0,
    displayWorkTimeAsDays: true,
    employeeCanRequest: false,
    customId: null
  })
  const trueFalseOptions = [
    {
      label: t('YES'),
      value: true
    },
    {
      label: t('NO'),
      value: false
    }
  ]
  const durationOption = [
    {
      label: t('BY_CONTRACT'),
      value: true
    },
    {
      label: t('CUSTOM'),
      value: false
    }
  ]
  const allowDisallowOptions = [
    {
      label: t('ALLOW'),
      value: true
    },
    {
      label: t('AUTOMATICALLY'),
      value: 'auto'
    },
    {
      label: t('DISALLOW'),
      value: false
    }
  ]
  const [errors, setErrors] = useState([])

  const canSave = () => {
    if (!form) return false
    if (!form.title) return false // name is required
    if (!form.tag) return false // short name is required
    if (errors && errors.length) return false
    return true
  }

  const getSanitizedCategoriesList = (categories) => {
    return categories.map(c => {
      const cat = Object.assign({}, c)
      if (typeof cat.maxDailyMinutes === typeof null || cat.maxDailyMinutes === '') delete cat.maxDailyMinutes
      if (typeof cat.maxYearlyNumber === typeof null || cat.maxYearlyNumber === '') delete cat.maxYearlyNumber
      return cat
    })
  }
  const handleCreate = async () => {
    const data = { ...form }
    if (canSave()) {
      if (!data.consider) {
        delete data.maxDailyMinutes
        delete data.vacation
        delete data.maxYearlyNumber
      }
      if (data.duration) {
        delete data.maxDailyMinutes
      }
      if (!data.title) data.title = ''
      if (!data.tag) data.tag = ''
      delete data.duration

      if (data.maxDailyMinutes) data.maxDailyMinutes = parseInt(data.maxDailyMinutes) * 60
      if (data.maxYearlyNumber) data.maxYearlyNumber = parseInt(data.maxYearlyNumber)

      if (data.employeeCanRequest && data.employeeCanRequest !== 'auto') {
        data.employeeCanRequest = toBoolean(data.employeeCanRequest)
        data.approveAutomatically = false
      }
      if (data.employeeCanRequest === 'auto') {
        data.employeeCanRequest = true
        data.approveAutomatically = true
      }

      await requestWorkspaceUpdate({
        id: workspaceId,
        data: {
          unavailabilityCategories: getSanitizedCategoriesList((Array.isArray(ws?.unavailabilityCategories) ? ws?.unavailabilityCategories : []).concat([data]))
        }
      }, auth)
      await loadWorkspaceDetail(workspaceId)
      setModal(null)
    } else {
      // check for potential errors
      if (!form.title || form.title === '' || !form.tag || form.tag === '') {
        const newErrs = [...errors]
        if (!errors.includes('titleMissing') && (!form.title || form.title === '')) {
          newErrs.push('titleMissing')
        }
        if (!errors.includes('tagMissing') && (!form.tag || form.tag === '')) {
          newErrs.push('tagMissing')
        }
        setErrors(newErrs)
      }
    }
  }
  const handleSet = (key, value) => {
    setForm({
      ...form,
      [key]: value
    })

    if (key === 'title') {
      if (errors.includes('titleMissing') && value && value !== '') {
        setErrors(errors.filter(e => e !== 'titleMissing'))
      }
      if (!errors.includes('titleMissing') && (!value || value === '')) {
        setErrors(errors.concat(['titleMissing']))
      }
    }
    if (key === 'tag') {
      if (errors.includes('tagMissing') && value && value !== '') {
        setErrors(errors.filter(e => e !== 'tagMissing'))
      }
      if (!errors.includes('tagMissing') && (!value || value === '')) {
        setErrors(errors.concat(['tagMissing']))
      }
    }
  }

  const isDisabledByConsider = () => {
    return !form.consider || form.consider === 'false'
  }

  const isDisabledByDuration = () => {
    return [true, 'true'].includes(form.duration)
  }

  return (
    <Modal
      testid='timeoff-category-modal'
      size={Modal.SIZES.M}
      className='ds-modal-unavailability-category-add'
      footerContent={
        <Flex stretch>
          <Button
            size={Button.SIZES.FULL}
            label={t('CLOSE')}
            onClick={() => setModal(null)}
          />
          <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
          <Button
            testid='create-category-button'
            disabled={!canSave()}
            onClickActiveWhenDisabled
            size={Button.SIZES.FULL}
            style={Button.STYLES.CONTAINED}
            color={Button.COLORS.GREEN}
            label={t('CREATE')}
            onClick={handleCreate}
          />
        </Flex>
      }
      headerContent={
        <Text type={Text.TYPES.BODY_LARGE} weight={Text.WEIGHTS.BOLD}>{t('WS_SETTINGS_CARD_UNAV_CAT_ADD')}</Text>
      }
      sections={[
        <>
          <Input
            testid='timeoff-category-name-input'
            label={t('NAME_V2')}
            hasError={Boolean(errors && errors.find(e => e === 'titleMissing'))}
            onChange={(value) => handleSet('title', value)}
            placeholder={t('WS_SETTINGS_CARD_UNAV_CAT_TITLE_PLACEHOLDER')}
            value={form.title}
            size={Input.SIZES.FULL_WIDTH}
          />

          <Input
            testid='timeoff-category-short-name-input'
            label={t('SHORT')}
            hasError={Boolean(errors && errors.find(e => e === 'tagMissing'))}
            onChange={(value) => handleSet('tag', value)}
            placeholder={t('WS_SETTINGS_CARD_UNAV_CAT_TAG_PLACEHOLDER')}
            value={form.tag}
            maxLength={3}
          />

          <Input
            label={t('WS_SETTINGS_CARD_UNAV_CAT_CUSTOM_ID')}
            onChange={(value) => handleSet('customId', value)}
            value={form.customId}
          />

          <div style={{ width: '100%', marginBottom: '0.5rem' }}>
            <Select
              testid='can-employee-request-select'
              label={t('WS_SETTINGS_CARD_UNAV_CAT_ALLOW')}
              options={allowDisallowOptions}
              onChange={(value) => handleSet('employeeCanRequest', value)}
              value={form.employeeCanRequest}
            />
          </div>

          {form.displayWorkTimeAsDays ? (
            <Input
              label={t('WS_SETTINGS_CARD_UNAV_CAT_FUND')}
              onChange={(value) => handleSet('maxYearlyNumber', value)}
              type='number'
              placeholder='20'
              value={form.maxYearlyNumber}
              disabled={isDisabledByConsider()}
              extraContent={<Flex>{t('DAYS_COUNT')}<Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} /></Flex>}
            />
          ) : (
            <div style={{ width: 'calc(50% - 0.375rem)', display: 'inline-block', marginRight: '0.375rem' }}>
              <TimeInput
                label={t('WS_SETTINGS_CARD_UNAV_CAT_FUND')}
                maxHours={999}
                value={(form.maxYearlyNumber || 0) * 8 * 60}
                onChange={(value) => handleSet('maxYearlyNumber', value / 8 / 60)}
              />
            </div>
          )}

          <Dropdown
            label={t('WS_SETTINGS_CARD_UNAV_CAT_WORKTIMEASDAYS')}
            type={Dropdown.TYPES.VARIABLE}
            style={Dropdown.STYLES.LIGHT}
            singleSelect
            options={trueFalseOptions}
            onChange={(v) => handleSet('displayWorkTimeAsDays', v.value)}
            value={trueFalseOptions.find(o => o.value === form.displayWorkTimeAsDays)}
          />

          <div style={{ width: '100%', marginBottom: '0.5rem' }}>
            <Select
              label={t('WS_SETTINGS_CARD_UNAV_CAT_CONSIDER')}
              options={trueFalseOptions}
              onChange={(value) => handleSet('consider', toBoolean(value))}
              value={form.consider}
            />
          </div>

          <div style={{ width: '100%', marginBottom: '0.5rem' }}>
            <Select
              label={t('WS_SETTINGS_CARD_UNAV_CAT_DURATION')}
              options={durationOption}
              onChange={(value) => handleSet('duration', toBoolean(value))}
              value={form.duration}
              disabled={isDisabledByConsider()}
            />
          </div>

          <Input
            label={t('WS_SETTINGS_CARD_UNAV_CAT_HOURS')}
            onChange={(value) => handleSet('maxDailyMinutes', value)}
            placeholder='2'
            type={isDisabledByDuration() ? 'hidden' : 'number'}
            value={form.maxDailyMinutes}
            disabled={isDisabledByConsider() || isDisabledByDuration()}
            size={Input.SIZES.FULL_WIDTH}
          />

          <div style={{ width: '100%', marginBottom: '0.5rem' }}>
            <Select
              label={t('WS_SETTINGS_CARD_UNAV_CAT_COUNT_VACATION')}
              options={trueFalseOptions}
              placeholder={t('CHOOSE_PERSONAL')}
              onChange={(value) => handleSet('vacation', toBoolean(value))}
              value={form.vacation}
              disabled={isDisabledByConsider()}
            />
          </div>

        </>
      ]}
    />
  )
}

UnavailabilityAdd.propTypes = {}
UnavailabilityAdd.defaultProps = {}

export default connect(UnavailabilityAdd)
