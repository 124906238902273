import React, { useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import './sidebar.scss'
import { miscUtil } from '@app/util'

const SIDES = Object.freeze({
  LEFT: 'left',
  RIGHT: 'right'
})

const UNDERLAY_STYLE = Object.freeze({
  NORMAL: 'normal',
  LIGHT: 'light',
  DISABLED: 'disabled'
})

export function Sidebar ({
  onClose,
  children,
  width,
  side,
  showNavBar,
  disableCloseByKeyboardShortcut,
  underlayStyle,
  underlayClosesSidebar,
  contentClassName
}) {
  const [canStartTransition, setCanStartTransition] = React.useState(false)

  const handleKeypress = (evt) => {
    // Esc keypress
    if (evt.keyCode === 27 && onClose && !disableCloseByKeyboardShortcut) {
      onClose()
    }
  }

  // when we scroll mouse wheel while while we have the underlay active, that scroll
  // event is propagated onto calendar sections, so user can scroll calendar while
  // it's covered by the underlay
  const propagateUnderlayScrollToCalendar = () => {
    const overlay = document.querySelector('.ds-sidebar-underlay')
    const scrollableDivs = document.querySelectorAll('.ds-c-section')

    const handleScroll = (e) => {
      let currentScrollableDiv = null
      scrollableDivs.forEach(div => {
        const rect = div.getBoundingClientRect()
        if (e.clientX >= rect.left && e.clientX <= rect.right &&
            e.clientY >= rect.top && e.clientY <= rect.bottom) {
          currentScrollableDiv = div
        }
      })

      if (currentScrollableDiv) {
        currentScrollableDiv.scrollTop += e.deltaY
        currentScrollableDiv.scrollLeft += e.deltaX
      }
    }

    if (overlay && scrollableDivs) {
      overlay.removeEventListener('wheel', handleScroll)
      overlay.addEventListener('wheel', handleScroll)
    }
  }
  setTimeout(() => { propagateUnderlayScrollToCalendar() }, 100)

  useEffect(() => {
    // move Intercom chat a bit to the left if the right sidebar is open, so they don't overlap
    miscUtil.intercomAdjustPosition(side === 'right')

    setCanStartTransition(true)
    document.addEventListener('keydown', handleKeypress, false)
    return () => {
      document.removeEventListener('keydown', handleKeypress, false)
    }
  }, [])

  const offset = canStartTransition ? '0' : `-${width}`

  return (
    <div
      className={classNames('ds-sidebar', {
        'no-underlay': underlayStyle === UNDERLAY_STYLE.DISABLED,
        'is-open': canStartTransition,
        'show-nav-bar': showNavBar
      })}
      style={{
        left: (underlayStyle === UNDERLAY_STYLE.DISABLED) ? ('calc(100% - ' + width + ')') : undefined
      }}
    >
      {underlayStyle !== UNDERLAY_STYLE.DISABLED && (
        <div onClick={underlayClosesSidebar ? onClose : () => {}} className={'ds-sidebar-underlay' + (underlayStyle === UNDERLAY_STYLE.LIGHT ? ' light-underlay' : '')} />
      )}
      <div
        className={classNames('ds-sidebar-content', contentClassName, side)}
        style={{
          width,
          marginLeft: side === 'left' ? offset : 'auto',
          marginRight: side === 'right' ? offset : 'auto'
        }}
      >
        {children}
      </div>
    </div>
  )
}

Sidebar.SIDES = SIDES
Sidebar.UNDERLAY_STYLE = UNDERLAY_STYLE

Sidebar.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.element.isRequired,
  width: PropTypes.string,
  side: PropTypes.string,
  underlayStyle: PropTypes.string,
  underlayClosesSidebar: PropTypes.bool,
  showNavBar: PropTypes.bool,
  disableCloseByKeyboardShortcut: PropTypes.bool,
  contentClassName: PropTypes.string
}
Sidebar.defaultProps = {
  onClose: undefined,
  width: '20rem',
  side: SIDES.RIGHT,
  underlayStyle: UNDERLAY_STYLE.NORMAL,
  underlayClosesSidebar: true,
  showNavBar: false,
  disableCloseByKeyboardShortcut: false,
  contentClassName: undefined
}
