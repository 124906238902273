import React from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  Loader,
  Err,
  Text,
  Spacing
} from '@ui'

import './index.scss'
import '../input/index.scss'

const TYPES = Object.freeze({
  DEFAULT: 'default',
  BORDERLESS: 'borderless'
})

const Select = ({
  label,
  value,
  options,
  placeholder,
  onChange,
  onClick,
  onBlur,
  hasError,
  errorMessage,
  className,
  tabIndex,
  isLoading,
  disabled,
  type,
  testid
}) => {
  const selectClassNames = classNames(
    'ds-input',
    'ds-select',
    {
      'has-error': hasError,
      'is-loading': isLoading,
      borderless: type === TYPES.BORDERLESS
    },
    className && className
  )
  const selectedOption = (options.filter((o) => o.value === value).length > 0) ? options.filter((o) => o.value === value)[0] : null

  return (
    <div className={selectClassNames}>
      {label && <Text weight={Text.WEIGHTS.BOLD} type={Text.TYPES.BODY_MEDIUM}><Spacing type={Spacing.TYPES.VERTICAL} size={Spacing.SIZES.SIZE_4}>{label}</Spacing></Text>}
      <div data-testid={testid} style={{ position: 'relative' }}>
        <select
          value={value === null ? undefined : value}
          className='ds-select-el tt-input'
          onChange={({ target }) => onChange(target.value)}
          onClick={(e) => onClick ? onClick(e) : {}}
          onBlur={onBlur}
          disabled={disabled}
          style={
            selectedOption && selectedOption.colorBackground && selectedOption.colorText
              ? { backgroundColor: '#' + selectedOption.colorBackground, color: '#' + selectedOption.colorText }
              : null
          }
          required
          tabIndex={tabIndex || 1}
        >
          {placeholder ? <option key='placeholder' value='' hidden>{placeholder}</option> : null}
          {options.map((o, idx) => (
            <option
              key={'opt_' + idx.toString() + '_' + o.value}
              value={o.value}
              style={o.colorBackground && o.colorText ? { backgroundColor: '#' + o.colorBackground, color: '#' + o.colorText } : null}
            >
              {o.label || t(o.value)}
            </option>
          ))}
        </select>
        {isLoading ? <Loader /> : disabled ? null : <div className='ds-select-caret' />}
      </div>
      {(hasError && errorMessage) ? <Err message={errorMessage} /> : null}
    </div>
  )
}
Select.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ])
    })
  ).isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  testid: PropTypes.string
}

Select.defaultProps = {
  value: '',
  type: TYPES.DEFAULT,
  testid: undefined
}

Select.TYPES = TYPES

export default Select
