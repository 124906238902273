import React from 'react'
import { t } from 'i18next'
import {
  Input,
  Icon,
  HelpTooltip,
  Checkbox,
  Text,
  Dropdown,
  Position,
  Flex,
  Spacing
} from '@ui'
import errUtil from '@app/util/error'

export default ({
  form,
  errors,
  idx,
  onFieldChange,
  onSubmit,
  workspace,
  positions,
  onBlur,
  onEmailBlur,
  onRemove,
  setSidebar
}) => {
  const contractTypeOptions = (workspace && workspace.contractTypes) ? workspace.contractTypes.map((ct) => {
    return {
      label: ct.name,
      value: ct.id
    }
  }) : []

  const positionsList = positions
    .filter((pos) => !pos.archived)
    .map((pos) => ({
      label: (
        <Position
          name={pos.name}
          color={pos.color}
        />
      ),
      value: pos.id
    }))

  return (
    <div className='ds-ea-invite'>
      {idx !== 0
        ? (
          <div className='ds-ea-invite-top-right'>
            <Icon
              ico={Icon.ICONS.close1}
              onClick={onRemove}
            />
          </div>
        )
        : null}

      <div className='ds-ea-invite-title'>
        <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL}>
          <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.VERTICAL}>
            <Text type={Text.TYPES.HEADER}>
              {t('EMPLOYEE') + ' #' + (idx + 1).toString()}
            </Text>
          </Spacing>
        </Spacing>
      </div>

      <table>
        <tbody>
          <tr>
            <td>
              <Input
                testid='first-name-input'
                size={Input.SIZES.FULL_WIDTH}
                autoFocus={idx === 0}
                className='ds-ea-first-name'
                type='text'
                value={form.firstName}
                label={t('FIRST_NAME')}
                placeholder={t('REG_FIRST_NAME_PLACEHOLDER')}
                hasError={errUtil.hasField('firstName', errors)}
                onChange={(val) => onFieldChange(idx, 'firstName', val)}
                onSubmit={onSubmit}
                onBlur={onBlur}
              />
            </td>
            <td>
              <Input
                testid='last-name-input'
                size={Input.SIZES.FULL_WIDTH}
                className='ds-ea-last-name'
                type='text'
                value={form.lastName}
                label={t('LAST_NAME')}
                placeholder={t('REG_LAST_NAME_PLACEHOLDER')}
                hasError={errUtil.hasField('lastName', errors)}
                onChange={(val) => onFieldChange(idx, 'lastName', val)}
                onSubmit={onSubmit}
                onBlur={onBlur}
              />
            </td>
          </tr>
          <tr>
            <td>
              <Dropdown
                testid='position-dropdown'
                label={t('POSITION')}
                singleSelect
                type={Dropdown.TYPES.VARIABLE}
                style={Dropdown.STYLES.LIGHT}
                size={Dropdown.SIZES.FULL_WIDTH}
                value={positionsList.find((pos) => pos.value === form.positionId)}
                placeholder={t('CHOOSE')}
                hasError={errUtil.hasField('positionId', errors)}
                options={positionsList}
                onChange={({ value: posId }) => onFieldChange(idx, 'positionId', posId)}
                extraDropdownContent={
                  <div
                    onClick={(e) => setSidebar('position-editor', { afterSubmit: (res) => onFieldChange(idx, 'positionId', res.id) })}
                  >
                    {'+ ' + t('ADD_POSITION')}
                  </div>
                }
              />
            </td>
            <td>
              <Dropdown
                testid='contract-dropdown'
                label={t('CONTRACT_TYPES')}
                singleSelect
                type={Dropdown.TYPES.VARIABLE}
                style={Dropdown.STYLES.LIGHT}
                size={Dropdown.SIZES.FULL_WIDTH}
                value={contractTypeOptions.find((v) => v.value === form.contractType)}
                placeholder={t('CHOOSE')}
                hasError={errUtil.hasField('contractType', errors)}
                options={contractTypeOptions}
                onChange={({ value: v }) => onFieldChange(idx, 'contractType', v)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div style={{ padding: '0 0 0.7rem 0' }}>
                <Flex align={Flex.POSITION.CENTER}>
                  <Text weight={Text.WEIGHTS.BOLD} type={Text.TYPES.BODY_MEDIUM}>
                    {t('EMPLOYEE_ADD_SEND_INV')}
                  </Text>
                  <HelpTooltip text={t('EMPLOYEE_ADD_INVITE_TOOLTIP')} />
                </Flex>
              </div>
              <Checkbox
                checked={form.sendInvite}
                onChange={(val) => {
                  onFieldChange(idx, 'sendInvite', val).then(() => {
                    if (!val) {
                      onFieldChange(idx, 'email', null)
                    }
                  })
                  if (onBlur) setTimeout(onBlur, 500)
                }}
              >
                {t('YES')}
              </Checkbox>
            </td>
            <td>
              <Flex>
                <Input
                  size={Input.SIZES.S}
                  className='ds-ea-hours'
                  type='number'
                  min={1}
                  value={form.hours}
                  label={t('HOURS_SLASH_MONTH')}
                  placeholder='ex. 160'
                  hasError={errUtil.hasField('hours', errors)}
                  onChange={(val) => onFieldChange(idx, 'hours', val)}
                />
                <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.HORIZONTAL} />
                <Spacing size={Spacing.SIZES.SIZE_4} type={Spacing.TYPES.VERTICAL}>
                  <HelpTooltip text={t('EMPLOYEE_ADD_HOURS_TOOLTIP')} />
                </Spacing>
              </Flex>
            </td>
          </tr>
          <tr>
            <td style={{ display: (form.sendInvite ? null : 'none') }}>
              <Input
                testid='email-input'
                size={Input.SIZES.FULL_WIDTH}
                className='ds-ea-email'
                type='email'
                value={form.email}
                label={t('EMAIL')}
                placeholder={t('REG_EMAIL_PLACEHOLDER_EMP')}
                onChange={(val) => {
                  onFieldChange(idx, 'email', val.toLowerCase())
                }}
                onSubmit={onSubmit}
                hasError={errUtil.hasField('email', errors)}
                onBlur={onEmailBlur}
              />

            </td>
            <td />
          </tr>
        </tbody>
      </table>

    </div>
  )
}
