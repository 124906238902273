"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcRolesGet = exports.AcRoleAssign = exports.AcRoleReassign = exports.AcRoleAssignDelete = exports.AcRoleCreate = exports.AcRoleDelete = exports.AcRoleUpdate = void 0;
var access_roles_1 = require("./access-roles");
Object.defineProperty(exports, "AcRoleUpdate", { enumerable: true, get: function () { return access_roles_1.AcRoleUpdate; } });
Object.defineProperty(exports, "AcRoleDelete", { enumerable: true, get: function () { return access_roles_1.AcRoleDelete; } });
Object.defineProperty(exports, "AcRoleCreate", { enumerable: true, get: function () { return access_roles_1.AcRoleCreate; } });
Object.defineProperty(exports, "AcRoleAssignDelete", { enumerable: true, get: function () { return access_roles_1.AcRoleAssignDelete; } });
Object.defineProperty(exports, "AcRoleReassign", { enumerable: true, get: function () { return access_roles_1.AcRoleReassign; } });
Object.defineProperty(exports, "AcRoleAssign", { enumerable: true, get: function () { return access_roles_1.AcRoleAssign; } });
Object.defineProperty(exports, "AcRolesGet", { enumerable: true, get: function () { return access_roles_1.AcRolesGet; } });
