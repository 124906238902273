/* eslint-disable react/jsx-fragments */
import React, { Fragment, useState, useEffect } from 'react'
import classNames from 'classnames/bind'
import { t } from 'i18next'
import moment from 'moment'

import requestOffersCreate from '@app/request/offers-create'
import {
  Loader,
  Position,
  Locality,
  Toggle,
  Checkbox,
  Dropdown,
  Button,
  Flex,
  Spacing
} from '@ui'
import { notification, miscUtil, timeUtil, routeUtil } from '@app/util'

import ActionsOnShifts from '@app/component/modals/confirm/actions-on-shifts'

import {
  getOfferTypeOptions,
  loadCandidatesForShift,
  getNewOfferCandidatesHTML
} from './helpers'
import ListShifts from './list-shifts'
import styles from './index.scss'
import connect from './connect'

const cx = classNames.bind(styles)

const TabCreate = (props) => {
  const {
    // from store:
    positions,
    shifts,
    employees,
    workspace,
    calendar,
    auth,
    setModal,
    updateLiveShift,
    deleteLiveShift,
    setCalendarMultiSelect,
    // from component's properties:
    setRelevantShifts,
    createType,
    createUpdateBefore,
    renderChildBtns,
    setIsProcessingAndCannotClose,
    // from HOC:
    isPluginEnabled
  } = props
  let {
    // from component's properties:
    relevantShifts
  } = props

  let selectedShifts = (relevantShifts && relevantShifts.length) ? relevantShifts.filter(s => s.isSelected) : []
  const [selectedSingleShift, setSelectedSingleShift] = useState(selectedShifts.length === 1 ? selectedShifts[0] : null)

  // apply the update from 'createUpdateBefore' prop if it was passed (pt 1/2)
  if (createUpdateBefore && selectedShifts.length === 1) {
    selectedShifts = selectedShifts.map(s => { if (s.id === selectedSingleShift.id) { return selectedSingleShift } else { return s } })
    relevantShifts = relevantShifts.map(s => { if (s.id === selectedSingleShift.id) { return selectedSingleShift } else { return s } })
  }

  const localitiesAreProbablyRelevant = isPluginEnabled('localities') && Array.isArray(workspace?.localities) && (workspace?.localities.length > 0)
  const warningsSupported = isPluginEnabled('laborlaw')
  const transfersSupported = workspace && workspace.transferGroups && workspace.transferGroups.length && workspace.transferGroups.reduce((a, s) => { return a + (s.workspaces ? s.workspaces.length : 0) }, 0)

  const [offerTypeOptions, setOfferTypeOptions] = useState(getOfferTypeOptions(selectedSingleShift, selectedShifts))
  const [optOfferType, setOptOfferType] = useState(createType || (offerTypeOptions.length ? offerTypeOptions[0].value : null))

  // set audience option defaults according to WS settings.offers.defaultAudienceOptions
  const wsDefOpts = workspace && workspace.settings && workspace.settings.offers && workspace.settings.offers && workspace.settings.offers.defaultAudienceOptions
  const getDefaultOptWithPosition = () => {
    let ret = wsDefOpts
      ? wsDefOpts.filterByPosition
      : true
    if (ret && !selectedSingleShift) ret = ['accordingToShift']
    return ret
  }
  const getDefaultOptWithLocality = () => {
    let ret = localitiesAreProbablyRelevant
      ? wsDefOpts
        ? wsDefOpts.filterByLocality
        : true
      : false
    if (ret && !selectedSingleShift) ret = ['accordingToShift']
    return ret
  }
  const getOptsWithArrayForRequest = (arr) => {
    if (!Array.isArray(arr)) return arr
    if (arr?.length === 1 && arr[0] === 'accordingToShift') return true
    return arr.filter(item => item !== 'accordingToShift')
  }

  const defaultOptWithSkill = wsDefOpts ? (wsDefOpts.filterBySkill ? 'sameAsShift' : 'any') : 'any'
  const defaultOptWithoutWarnings = warningsSupported
    ? wsDefOpts
      ? wsDefOpts.filterByWarnings
      : Object.keys(employees).length <= 50 // keep filterByWarnings unchecked by default on large workspaces
    : false
  const defaultOptEveryone = (!getDefaultOptWithPosition() && !getDefaultOptWithLocality() && (defaultOptWithSkill === 'any') && !defaultOptWithoutWarnings)

  const [optEveryone, setOptEveryone] = useState(defaultOptEveryone)
  const [optWithPosition, setOptWithPosition] = useState(getDefaultOptWithPosition())
  const [optWithSkill, setOptWithSkill] = useState(defaultOptWithSkill)
  const [optWithLocality, setOptWithLocality] = useState(getDefaultOptWithLocality())
  const [optWithoutWarnings, setOptWithoutWarnings] = useState(defaultOptWithoutWarnings)
  const [optNotify, setOptNotify] = useState(true)
  const [optTransfers, setOptTransfers] = useState(false)

  const [candidates, setCandidates] = useState([])
  const [candidatesTransfer, setCandidatesTransfer] = useState([])
  const [selectedTransferWorkspaces, setSelectedTransferWorkspaces] = useState(null)
  const [extraIncludes, setExtraIncludes] = useState([])
  const [extraExcludes, setExtraExcludes] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [loadDataTimer, setLoadDataTimer] = useState(setTimeout(() => {}, 0))

  const loadCandidates = (sss) => {
    if (sss) {
      // use loadDataTimer to ensure we don't load the candidates more often than once every 1500ms
      clearTimeout(loadDataTimer)
      setLoadDataTimer(setTimeout(() => {
        loadCandidatesForShift(sss, {
          realShiftId: undefined,
          filterByLocality: !optEveryone && optWithLocality,
          filterByPosition: !optEveryone && optWithPosition,
          filterBySkill: !optEveryone && (optWithSkill === 'sameAsShift'),
          filterByWarnings: !optEveryone && optWithoutWarnings,
          auth,
          workspace,
          loadTransfers: optTransfers,
          offerType: optOfferType,
          beforeLoadCallback: () => { setIsLoading(true) },
          afterLoadCallback: () => { setIsLoading(false) }
        }).then(res => {
          setCandidates(res.candidates)
          setCandidatesTransfer(res.candidatesTransfer)
          if (Array.isArray(res.candidatesTransfer) && optTransfers) {
            if (selectedTransferWorkspaces === null) {
              setSelectedTransferWorkspaces(res.candidatesTransfer.map(ct => ct.workspaceId))
            } else {
              setSelectedTransferWorkspaces(selectedTransferWorkspaces.filter(wid => res.candidatesTransfer.some(ct => ct.workspaceId === wid)))
            }
          }
        })
      }, 1500))
    } else {
      if (candidates?.length) setCandidates([])
      if (candidatesTransfer?.length) setCandidatesTransfer([])
      if (selectedTransferWorkspaces?.length) setSelectedTransferWorkspaces(null)
    }
  }

  // when user changes the audience setup, get the list of candidates
  useEffect(() => {
    loadCandidates(selectedSingleShift)
  }, [optEveryone, optWithSkill, optWithoutWarnings, JSON.stringify(optWithLocality), JSON.stringify(optWithPosition), optTransfers, optOfferType])

  // when relevantShifts change and exactly one shift is selected, update the offerTypeOptions and selectedSingleShift and reload candidates
  useEffect(() => {
    const sShifts = (relevantShifts && relevantShifts.length) ? relevantShifts.filter(s => s.isSelected) : []
    if (sShifts.length === 1) {
      if (!selectedSingleShift || miscUtil.safeStringify(selectedSingleShift) !== miscUtil.safeStringify(sShifts[0])) {
        setSelectedSingleShift(sShifts[0])
        setOfferTypeOptions(getOfferTypeOptions(sShifts[0], selectedShifts))
        if (!isLoading) loadCandidates(sShifts[0])
      }
    }
  }, [relevantShifts])

  // when selectedSingleShift changes, we need to re-set optWithLocality & optWithPosition, since they use different options for single- & multi-selection
  useEffect(() => {
    setOptWithLocality(getDefaultOptWithLocality())
    setOptWithPosition(getDefaultOptWithPosition())
  }, [selectedSingleShift])

  // on mount
  useEffect(() => {
    // apply the update from 'createUpdateBefore' prop if it was passed (pt 2/2)
    if (createUpdateBefore && selectedShifts.length === 1) {
      const newSss = Object.assign({}, selectedShifts[0], createUpdateBefore)
      if (miscUtil.safeStringify(selectedSingleShift) !== miscUtil.safeStringify(newSss)) {
        setSelectedSingleShift(newSss)
      }
    }
  }, [])

  // generate error message if submit is not allowed
  const problemWithSubmit = () => {
    const externalCandsCount = candidatesTransfer.reduce((a, s) => { return a + parseInt(s.candidatesCount) }, 0)

    if (selectedShifts.length === 0) return 'mustSelectShifts'

    if (optOfferType === 'unassign') {
      if (selectedShifts.filter(evt => evt.userId).length === 0) return 'noShiftsToUnassign'
    }

    if (optOfferType === 'drop') {
      const selectedCands = candidates.filter(cn => cn.suitable === true && !extraExcludes.includes(cn.userId))
        .map(c => c.userId)
        .concat(candidates.filter(cn => cn.suitable === false && extraIncludes.includes(cn.userId)).map(c => c.userId))
      if (selectedShifts.length === 1 && selectedCands.length === 0 && externalCandsCount === 0) return 'noSelectedCandsDrop'
    }

    if (optOfferType === 'swap') {
      if (selectedShifts.length > 1) return 'cantRunBatchSwaps'
      if (extraIncludes.length === 0) return 'noSelectedCandsSwap'
    }

    return null
  }

  const handleSubmit = () => {
    // unassign
    if (optOfferType === 'unassign') {
      updateLiveShift(null, { userId: null }, selectedShifts.filter(evt => evt.userId).map(evt => evt.id))
      setModal(null)
    }

    // delete
    if (optOfferType === 'delete') {
      deleteLiveShift(null, selectedShifts.map(evt => evt.id))
      setModal(null)
    }

    if (['drop', 'swap'].includes(optOfferType)) {
      let runCreate = async () => {}
      setIsLoading(true)

      // drop
      if (optOfferType === 'drop') {
        runCreate = async () => {
          if (createUpdateBefore && selectedSingleShift) {
            console.log('Applying update to shift', selectedSingleShift.id, 'before we start the offer:', createUpdateBefore)
            await updateLiveShift(selectedSingleShift.id, createUpdateBefore)
          }

          const optWithLocalityForRequest = getOptsWithArrayForRequest(optWithLocality)
          const optWithPositionForRequest = getOptsWithArrayForRequest(optWithPosition)

          setIsProcessingAndCannotClose(0)
          requestOffersCreate({
            workspace: workspace.id,
            shifts: selectedShifts.map(evt => evt.id),
            options: {
              type: 'drop',
              audience: {
                options: {
                  filterByLocality: Array.isArray(optWithLocalityForRequest) ? undefined : optWithLocalityForRequest,
                  filterByLocalities: Array.isArray(optWithLocalityForRequest) ? optWithLocalityForRequest : undefined,
                  filterByPosition: Array.isArray(optWithPositionForRequest) ? undefined : optWithPositionForRequest,
                  filterByPositions: Array.isArray(optWithPositionForRequest) ? optWithPositionForRequest : undefined,
                  filterBySkill: optWithSkill === 'sameAsShift',
                  filterByWarnings: optWithoutWarnings
                },
                includes: extraIncludes,
                excludes: extraExcludes
              },
              transfer: Boolean(optTransfers && (selectedShifts?.length === 1 ? selectedTransferWorkspaces?.length : true)),
              transferWorkspaces: selectedTransferWorkspaces?.length ? selectedTransferWorkspaces : undefined,
              notifyEmployees: optNotify
            }
          },
          auth,
          (offersCretedSoFar, offersToCreate) => {
            // this callback is called after each processed batch - and we use it to update the progress percentage displayed in parent component
            setIsProcessingAndCannotClose(Math.round(offersCretedSoFar / offersToCreate * 100))
          }).then(res => {
            setIsProcessingAndCannotClose(false)
            if (res && res.error) {
              notification.error({ title: 'ERR_MESSAGE.offerCreationFailed', message: res.error.message })
              setModal(null)
            } else {
              notification.success({ code: 'offerCreatedDrop' + (selectedShifts.length > 1 ? 'Multi' : '') })
              const offersWithoutCands = (res || []).filter(off => !optTransfers && (!off.swaps || off.swaps.length === 0) && (!off.announceAction || !off.announceAction.targetUsers || off.announceAction.targetUsers.length === 0))

              if (offersWithoutCands.length) {
                const shiftsWithoutCands = shifts.map(s => {
                  const relevantOff = offersWithoutCands.find(off => off.shiftId === s.id)
                  if (relevantOff) {
                    return Object.assign({}, s, { hasOffer: relevantOff })
                  } else {
                    return null
                  }
                }).filter(Boolean)

                const shiftsActionFnRef = React.createRef()
                setModal('confirm', {
                  title: t('OFF_CONFIRM_NO_CANDS_TITLE'),
                  subtitle: t('OFF_CONFIRM_NO_CANDS_SUBTITLE_X', { x: shiftsWithoutCands.length }),
                  recordsList: shiftsWithoutCands,
                  text: (
                    <ActionsOnShifts
                      labelTxt={t('OFF_CONFIRM_NO_CANDS_RELATED_SHIFTS_X', { x: shiftsWithoutCands.length })}
                      shifts={shiftsWithoutCands}
                      shiftsActionFnRef={shiftsActionFnRef}
                    />
                  ),
                  confirmLabel: t('CONFIRM'),
                  onConfirm: () => {
                    if (shiftsActionFnRef.current) {
                      shiftsActionFnRef.current()
                    }
                  },
                  cancelLabel: t('CLOSE'),
                  onCancel: () => {},
                  overSidebar: true
                })
              } else {
                setModal(null)
              }
            }
            setIsLoading(false)
          })
        }
      }

      // swap
      if (optOfferType === 'swap') {
        runCreate = async () => {
          if (createUpdateBefore && selectedSingleShift) {
            console.log('Applying update to shift', selectedSingleShift.id, 'before we start the offer:', createUpdateBefore)
            await updateLiveShift(selectedSingleShift.id, createUpdateBefore)
          }

          requestOffersCreate({
            workspace: workspace.id,
            shifts: selectedShifts.map(evt => evt.id),
            options: {
              type: 'swap',
              transfer: false,
              swaps: selectedSingleShift ? extraIncludes : undefined,
              notifyEmployees: optNotify
            }
          }, auth).then(res => {
            if (res && res.error) {
              notification.error({ title: 'ERR_MESSAGE.offerCreationFailed', message: res.error.message })
            } else {
              notification.success({ code: 'offerCreatedSwap' + (selectedShifts.length > 1 ? 'Multi' : '') })
            }
            setIsLoading(false)
            setModal(null)
          })
        }
      }

      runCreate()
    }
  }

  const footerButtons = [
    <Fragment key={0}>
      <Button
        label={t('OFF_BTN_NO_ACTION')}
        onClick={() => { setModal(null) }}
      />
      <Button
        testid='offer-modal-submit-button'
        style={Button.STYLES.CONTAINED}
        color={optOfferType === 'delete' ? Button.COLORS.RED : Button.COLORS.PRIMARY}
        label={offerTypeOptions.find(oo => oo.value === optOfferType) ? offerTypeOptions.find(oo => oo.value === optOfferType).buttonLabel : t('SUBMIT')}
        loading={isLoading}
        disabled={!!(problemWithSubmit() || isLoading)}
        onClickActiveWhenDisabled
        onClick={() => {
          const problem = problemWithSubmit()
          if (problem) {
            notification.error({ title: '', message: t('OFF_SUBMIT_PROBLEM.' + problem) })
          } else {
            handleSubmit()
          }
        }}

      />
    </Fragment>
  ]
  const problem = problemWithSubmit()
  useEffect(
    () => { renderChildBtns(footerButtons) },
    [
      problem,
      isLoading,
      extraIncludes,
      extraExcludes,
      optNotify,
      optWithLocality,
      optWithPosition,
      optWithSkill,
      optWithoutWarnings,
      optTransfers,
      selectedTransferWorkspaces
    ]
  )

  // prepare the positionsList
  const positionsList = positions.filter(pos => !pos.archived).map((pos) => {
    return {
      label: (
        <Position
          name={pos.name}
          color={pos.color}
        />
      ),
      value: pos.id
    }
  })
  if (!selectedSingleShift) {
    positionsList.unshift({
      label: t('OFF_AUDIENCE_LOC_MULTI'),
      value: 'accordingToShift'
    })
  }

  // prepare the localitiesList
  const localitiesOnWS = workspace && workspace.localities && workspace.localities
  const localitiesList = (localitiesOnWS || []).map(loc => {
    return {
      label: <Locality {...loc} />,
      value: loc.id
    }
  })
  if (!selectedSingleShift) {
    localitiesList.unshift({
      label: t('OFF_AUDIENCE_LOC_MULTI'),
      value: 'accordingToShift'
    })
  }

  return (
    <div className={cx('ds-mod-content', 'ds-mod-content-show')}>
      <ListShifts
        disabled={isLoading}
        relevantShifts={relevantShifts}
        setRelevantShifts={(relShifts) => {
          setRelevantShifts(relShifts)
          if (relShifts.filter(s => s.isSelected).length === 1) {
            const sss = relShifts.find(s => s.isSelected)
            setSelectedSingleShift(sss)
            loadCandidates(sss)
          } else {
            setSelectedSingleShift(null)
          }
          setExtraIncludes([])
          setExtraExcludes([])
        }}
        noShiftsContent={(
          <div className={cx('ds-mod-row', 'no-shifts-content')}>
            <div style={{ marginBottom: '8ex' }}>{t('OFF_SHIFTS_FALLBACK_CREATE')}</div>
            <Button
              label={t('OFF_SHIFTS_FALLBACK_CREATE_BTN')}
              onClick={(e) => {
                routeUtil.navigate('/schedule/' + ((calendar && calendar.date) || 'month') + '/' + ((calendar && calendar.date && moment(calendar.date).format('YYYY-MM-DD')) || timeUtil.getInitialCalendarDate()))
                setCalendarMultiSelect({
                  isActive: true,
                  isSelectingTargets: false,
                  sourceEvents: [],
                  targets: []
                })
                setModal(null)
              }}
            />
          </div>
        )}
        subtitleTxt='OFF_TAB_CREATE'
      />

      <div className={cx('ds-mod-off-wrap')}>
        <div className={cx('ds-mod-off-twocolumn')}>
          <div className={cx('ds-mod-off-details')}>

            <div className={cx('ds-mod-row')}>
              <div
                className={cx('ds-title', { 'is-disabled': relevantShifts.length === 0 })}
              >{t('OFF_OFFER_ACTION')}
              </div>
            </div>
            <div
              className={cx('ds-mod-row', 'is-section-title', { 'is-disabled': relevantShifts.length === 0 })}
              style={{ fontSize: '87.5%' }}
            >
              {t('OFF_OFFER_ACTION_SELECT')}
            </div>

            {selectedShifts.length === 0 ? (
              <div className={cx('ds-mod-row', { 'is-disabled': relevantShifts.length === 0 })}>
                {t('OFF_SHOW_NOT_SELECTED')}
              </div>
            ) : (
              <Fragment>

                {/* offer type selection */}
                <div className={cx('ds-mod-row')}>
                  <Dropdown
                    singleSelect
                    size={Dropdown.SIZES.FULL_WIDTH}
                    type={Dropdown.TYPES.VARIABLE}
                    style={Dropdown.STYLES.LIGHT}

                    disabled={isLoading}
                    value={[offerTypeOptions.find(o => o.value === optOfferType)].filter(Boolean)}
                    options={offerTypeOptions}
                    onChange={(val) => {
                      if (candidates.length) setCandidates([])
                      if (candidatesTransfer.length) setCandidatesTransfer([])
                      if (extraIncludes.length) setExtraIncludes([])
                      if (extraExcludes.length) setExtraExcludes([])
                      setOptOfferType(val.value)
                    }}
                  />
                </div>

                {/* audience setup */}
                {optOfferType === 'drop' && (
                  <div className={cx('ds-mod-audience-setup-rows')}>
                    <div className={cx('ds-mod-row', 'is-section-title')}>
                      {t('OFF_WHO_IS_AUDIENCE')}
                    </div>

                    {/* select everyone */}
                    <div className={cx('ds-mod-row', { 'is-true': optEveryone })} style={{ marginBottom: '0.4ex' }}>
                      <Toggle
                        testid='select-all-toggle'
                        disabled={isLoading}
                        value={optEveryone}
                        onChange={(val) => {
                          if (val === true || val === 'true') {
                            setOptWithPosition(false)
                            setOptWithLocality(false)
                            setOptWithoutWarnings(false)
                            setExtraIncludes(candidates?.filter(cn => !cn.suitable).map(cn => cn.userId) || [])
                          } else {
                            setOptWithPosition(true)
                            setOptWithLocality(Boolean(localitiesAreProbablyRelevant))
                            setOptWithoutWarnings(Boolean(warningsSupported))
                            setExtraIncludes([])
                          }
                          setOptEveryone(val)
                        }}
                      />
                      {t('OFF_AUDIENCE_EVERYONE')}
                    </div>

                    {/* with position */}
                    <div className={cx('ds-mod-row', { 'is-true': optWithPosition })} style={{ paddingBottom: selectedSingleShift?.idealSkill ? '0.6rem' : undefined }}>
                      <Checkbox
                        checked={Boolean(optWithPosition)}
                        onChange={(val) => {
                          if (val === false) {
                            setOptWithPosition(false)
                          } else {
                            const def = getDefaultOptWithPosition()
                            const newVal = def === false
                              ? selectedSingleShift
                                ? true
                                : ['accordingToShift']
                              : def
                            setOptWithPosition(newVal)
                          }
                        }}
                        disabled={optEveryone || isLoading}
                      />
                      <Spacing
                        size={Spacing.SIZES.SIZE_8}
                        type={Spacing.TYPES.HORIZONTAL}
                      >
                        <div style={{ whiteSpace: 'nowrap' }}>{t('OFF_AUDIENCE_POS_1')}</div>
                      </Spacing>

                      <Fragment>
                        <Spacing
                          size={Spacing.SIZES.SIZE_8}
                          type={Spacing.TYPES.HORIZONTAL}
                        >
                          <Dropdown
                            label={null}
                            searchable
                            sortedOptions={false}
                            type={Dropdown.TYPES.VARIABLE}
                            style={Dropdown.STYLES.BORDERLESS}
                            size={Dropdown.SIZES.FULL_WIDTH}
                            options={positionsList}
                            value={
                              Array.isArray(optWithPosition)
                                ? positionsList.filter((pos) => optWithPosition.includes(pos.value))
                                : positionsList.find((pos) => pos.value === selectedSingleShift?.positionId)
                            }
                            placeholder={t('SELECT_JOB_POSITION')}
                            onChange={(values) => {
                              if (values.some(v => v.value === 'accordingToShift') && (!Array.isArray(optWithPosition) || !optWithPosition.includes('accordingToShift'))) {
                                setOptWithPosition(['accordingToShift'])
                              } else {
                                if (Array.isArray(values) && values.length === 0) {
                                  setOptWithPosition(false)
                                } else {
                                  setOptWithPosition(values.map(v => v.value).filter(v => v !== 'accordingToShift'))
                                }
                              }
                            }}
                            disabled={!optWithPosition}
                          />
                        </Spacing>
                      </Fragment>
                    </div>

                    {selectedSingleShift?.idealSkill && (
                      <div className={cx('ds-mod-row', { 'is-true': optWithPosition })} style={{ paddingLeft: '2.3rem' }}>
                        {t('OFF_AUDIENCE_POS_2')}
                        <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL}>
                          <Dropdown
                            label={null}
                            singleSelect
                            sortedOptions={false}
                            size={Dropdown.SIZES.LARGE}
                            type={Dropdown.TYPES.VARIABLE}
                            style={Dropdown.STYLES.BORDERLESS}
                            options={[
                              { value: 'any', label: t('OFF_AUDIENCE_ANY_SKILL') },
                              { value: 'sameAsShift', label: t('SKILL_NAME_' + selectedSingleShift.idealSkill) }
                            ]}
                            value={[{
                              value: optWithSkill,
                              label: (optWithSkill === 'any' ? t('OFF_AUDIENCE_ANY_SKILL') : t('SKILL_NAME_' + selectedSingleShift.idealSkill))
                            }]}
                            placeholder={null}
                            onChange={(v) => {
                              setOptWithSkill(v.value)
                            }}
                            disabled={!optWithPosition}
                          />
                        </Spacing>
                      </div>
                    )}

                    {/* with locality */}
                    {localitiesAreProbablyRelevant && (
                      <div className={cx('ds-mod-row', { 'is-true': optWithLocality })}>
                        <Checkbox
                          checked={Boolean(optWithLocality)}
                          onChange={(val) => {
                            if (val === false) {
                              setOptWithLocality(false)
                            } else {
                              const def = getDefaultOptWithLocality()
                              const newVal = def === false
                                ? selectedSingleShift
                                  ? true
                                  : ['accordingToShift']
                                : def
                              setOptWithLocality(newVal)
                            }
                          }}
                          disabled={optEveryone || isLoading}
                        />
                        <Spacing
                          size={Spacing.SIZES.SIZE_8}
                          type={Spacing.TYPES.HORIZONTAL}
                        >
                          <div style={{ whiteSpace: 'nowrap' }}>{t('OFF_AUDIENCE_LOC')}</div>
                        </Spacing>

                        <Spacing
                          size={Spacing.SIZES.SIZE_8}
                          type={Spacing.TYPES.HORIZONTAL}
                        >
                          <Dropdown
                            label={null}
                            searchable
                            sortedOptions={false}
                            type={Dropdown.TYPES.VARIABLE}
                            style={Dropdown.STYLES.BORDERLESS}
                            size={Dropdown.SIZES.LARGE}
                            options={localitiesList}
                            value={
                              Array.isArray(optWithLocality)
                                ? localitiesList.filter((loc) => optWithLocality.includes(loc.value))
                                : localitiesList.find((loc) => loc.value === selectedSingleShift?.localityId)
                            }
                            placeholder={t('SELECT')}
                            onChange={(values) => {
                              if (values.some(v => v.value === 'accordingToShift') && (!Array.isArray(optWithLocality) || !optWithLocality.includes('accordingToShift'))) {
                                setOptWithLocality(['accordingToShift'])
                              } else {
                                if (Array.isArray(values) && values.length === 0) {
                                  setOptWithLocality(false)
                                } else {
                                  setOptWithLocality(values.map(v => v.value).filter(v => v !== 'accordingToShift'))
                                }
                              }
                            }}
                            disabled={!optWithLocality}
                          />
                        </Spacing>
                      </div>
                    )}

                    {/* without warnings */}
                    {warningsSupported
                      ? (
                        <div className={cx('ds-mod-row', { 'is-true': optWithoutWarnings })}>
                          <Checkbox
                            checked={optWithoutWarnings}
                            onChange={(val) => {
                              setOptWithoutWarnings(val)
                            }}
                            disabled={optEveryone || isLoading}
                          />
                          <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL}>
                            <Flex align={Flex.POSITION.CENTER}>
                              {t('OFF_AUDIENCE_NOWARN')}
                            </Flex>
                          </Spacing>
                        </div>
                      )
                      : null}

                    {/* transfer employees */}
                    {transfersSupported
                      ? (
                        <div className={cx('ds-mod-row', { 'is-true': optTransfers })} style={{ marginTop: '0.4ex' }}>
                          <Toggle
                            value={optTransfers}
                            onChange={(val) => {
                              setOptTransfers(val)
                            }}
                            disabled={isLoading}
                          />
                          {t('OFF_AUDIENCE_TRANS')}
                        </div>
                      ) : null}

                  </div>
                )}

                <div className={cx('ds-mod-notification-setup-rows')}>
                  <div className={cx('ds-mod-row', 'is-section-title')}>
                    {t('OFF_NOTIF_TITLE')}
                  </div>
                  <div className={cx('ds-mod-row', { 'is-true': optNotify })}>
                    <Toggle
                      value={optNotify}
                      onChange={(val) => {
                        setOptNotify(val)
                      }}
                      disabled={isLoading}
                    />
                    <div style={{ position: 'relative' }}>
                      {t('OFF_NOTIF_TXT_1')}
                      <div className={cx('is-note')}>{t('OFF_NOTIF_TXT_2')}</div>
                    </div>
                  </div>
                </div>

              </Fragment>
            )}
          </div>

          <div className={cx('ds-mod-off-audience')}>

            <div className={cx('ds-mod-row')}>
              <div
                className={cx('ds-title', { 'is-disabled': !relevantShifts.length })}
              >{t('OFF_AUDIENCE' + (optOfferType === 'swap' ? '_SWAP' : ''))}
              </div>
            </div>

            {selectedShifts.length === 0 && (
              <div
                className={cx('ds-mod-row', 'ds-mod-audience-explain', { 'is-disabled': !relevantShifts.length })}
              >{t('OFF_AUDIENCE_NOTSEL')}
              </div>
            )}

            {selectedShifts.length > 1 && (
              <div
                className={cx('ds-mod-row', 'ds-mod-audience-explain', { 'is-disabled': !relevantShifts.length })}
              >{t('OFF_AUDIENCE_MULTI')}
              </div>
            )}

            {selectedShifts.length === 1 && !isLoading && (
              getNewOfferCandidatesHTML({
                localitiesAreProbablyRelevant,
                warningsEnabled: !!optWithoutWarnings,
                employees,
                workspace,
                optOfferType,
                optTransfers,
                selectedSingleShift,
                candidates,
                candidatesTransfer,
                extraExcludes,
                extraIncludes,
                selectedTransferWorkspaces,
                setExtraExcludes,
                setExtraIncludes,
                setSelectedTransferWorkspaces,
                positions
              })
            )}

            {isLoading && <Loader size={Loader.SIZES.SMALL} />}

          </div>

        </div>
      </div>

    </div>
  )
}

export default connect(TabCreate)
