"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.updatedCustomAttributeData = exports.setCustomAttributesData = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = [];
// A good idea.
// A terribly rushed implementation
const customAttributeDataSlice = (0, toolkit_1.createSlice)({
    name: 'customAttributeDataSlice',
    initialState,
    reducers: {
        setCustomAttributesData: (state, action) => {
            state = [...action.payload];
            return state;
        },
        updatedCustomAttributeData: (state, action) => {
            const index = state.findIndex(att => att.attributeId === action.payload.attributeId && att.userId === action.payload.userId);
            if (index === -1) {
                state = [...state, action.payload];
                return state;
            }
            state[index] = action.payload;
            return state;
        }
    }
});
exports.default = customAttributeDataSlice.reducer;
_a = customAttributeDataSlice.actions, exports.setCustomAttributesData = _a.setCustomAttributesData, exports.updatedCustomAttributeData = _a.updatedCustomAttributeData;
