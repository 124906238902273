import React, { Fragment } from 'react'
import { t } from 'i18next'

import {
  Modal,
  Err,
  Button,
  Spacing
} from '@ui'
import {
  notification,
  valUtil,
  errorUtil
} from '@app/util'

import Invite from './invite'
import connect from './connect'
import './index.scss'

class EmployeeAdd extends React.Component {
  constructor (props) {
    super(props)
    this.invite = this._invite.bind(this)
    this.handleOnFieldChange = this._onFieldChange.bind(this)
    this.handleOnSubmit = this._onSubmit.bind(this)
    this.state = {
      invites: [this.invite()],
      recentlyCopiedUrl: false,
      defaultContractId: 'default'
    }
  }

  componentDidMount () {
    this.props.resetErrors()
    const contractTypesOnWS = (this.props.workspace && this.props.workspace.contractTypes) ? this.props.workspace.contractTypes.map((ct) => ct.id) : []
    this.setState({
      defaultContractId: contractTypesOnWS[0],
      invites: [this.invite(contractTypesOnWS[0])]
    })
  }

  _invite (defaultContractId) {
    const { defaultPosition } = this.props
    const positions = this.props.positions.filter(p => !p.archived)
    return {
      form: {
        firstName: null,
        lastName: null,
        email: null,
        hours: 160,
        contractType: defaultContractId || 'default',
        positionId: defaultPosition || (positions && positions.length ? positions[0].id : 'newPosition'),
        sendInvite: true
      },
      errors: []
    }
  }

  _onSubmit () {
    const { isLoading, setModal } = this.props
    if (isLoading) return

    const invites = this._getCurrentInvites()
    if (this._hasErrors() || (this._hasEmailErrors() && invites.find((i) => i.form.sendInvite === true))) return

    const formsWithData = invites.map((i) => i.form).filter((i) => !!i.firstName)

    if (!formsWithData.length) return

    this.props.sendInvites(formsWithData).then(() => {
      if (this.props.afterSubmit) this.props.afterSubmit(invites)
      setModal(null)
      notification.success({ code: 'employeesInvited' })
    })
  }

  async _onFieldChange (idx, key, value) {
    const update = {}
    update[key] = value
    const invites = [...this.state.invites]
    invites[idx] = {
      form: Object.assign({}, invites[idx].form, update),
      errors: errorUtil.filterField(key, invites[idx].errors)
    }

    this.setState({ invites })
    this.props.resetErrors()
  }

  _getCurrentInvites () {
    return this.state.invites.map((i, idx) => {
      return {
        form: i.form,
        errors: (!i.form.firstName && !i.form.lastName && !i.form.email) ? []
          : [
            // actual definition of the errors for the invite:
            valUtil.required(i.form.firstName, 'firstName'),
            valUtil.required(i.form.lastName, 'lastName'),
            (valUtil.requiredPosition(i.form.positionId, 'positionId')),
            valUtil.required(i.form.hours, 'hours'),
            valUtil.required(i.form.contractType, 'contractType')
          ].filter((e) => !!e)
      }
    })
  }

  _hasErrors () {
    const invites = this._getCurrentInvites()

    // count the errors
    const hasErrors = []

    invites.filter((i) => !!i.errors.length).forEach(i => {
      i.errors.forEach(er => hasErrors.push(er))
    })

    if (hasErrors.length) {
      this.setState({ invites })
      return true
    }
    return false
  }

  _hasEmailErrors () {
    const invites = this._getCurrentInvites().map((i, idx) => {
      const errorsWithEmail = i.errors || []
      errorsWithEmail.push(i.form.sendInvite ? valUtil.email(i.form.email, 'email') : null) // if 'sendInvite' is true, we need a valid email
      return {
        form: i.form,
        errors: (!i.form.firstName && !i.form.lastName && !i.form.email) ? []
          : errorsWithEmail.filter((e) => !!e)
      }
    })

    // count the errors
    const hasErrors = []

    // little UX hack: if there are at least 2 filled invites, ignore the 'missing email' error.
    invites.filter((i) => !!i.errors.length).forEach(i => {
      if (invites.length >= 2 && (!i.form.email || i.form.email === '')) {
        i.errors.filter(er => er && er.field !== 'email').forEach(er => hasErrors.push(er))
      } else {
        i.errors.forEach(er => hasErrors.push(er))
      }
    })

    if (hasErrors.length) {
      this.setState({ invites })
      return true
    }
    return false
  }

  render () {
    const { isLoading, setModal, workspace, positions, setSidebar } = this.props
    const { invites } = this.state
    const allErrors = this.props.errors.concat([].concat.apply([], invites.map((i) => i.errors))).filter((e) => !!e)

    return (
      <Modal
        testid='employee-add-modal'
        size={Modal.SIZES.L}
        className='ds-employee-add'
        isLoading={isLoading}
        footerContent={
          <>
            <Button
              bold
              size={Button.SIZES.LARGE}
              label={t('CLOSE')}
              onClick={() => { setModal(null) }}
            />
            <Button
              testid='save-button'
              bold
              size={Button.SIZES.LARGE}
              style={Button.STYLES.CONTAINED}
              color={Button.COLORS.GREEN}
              disabled={
                (allErrors && allErrors.length) ||
              !this._getCurrentInvites().find(i => i.form.firstName)
              }
              onClickActiveWhenDisabled
              label={t('SAVE')}
              onClick={this.handleOnSubmit}
            />
          </>
        }
        headerContent={<div className='ds-title'>{t('EMPLOYEE_ADD_TITLE')}</div>}
        sections={[
          <Fragment key={1}>
            <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.VERTICAL}>
              {t('EMPLOYEE_ADD_TXT')}
            </Spacing>
            <div className='ds-ea-invites'>
              {invites.map((i, idx) => {
                return (
                  <Invite
                    workspace={workspace}
                    positions={positions}
                    idx={idx}
                    key={idx}
                    setSidebar={setSidebar}
                    onFieldChange={this.handleOnFieldChange}
                    onSubmit={this.handleOnSubmit}
                    onBlur={() => this._hasErrors()}
                    onEmailBlur={() => this._hasEmailErrors()}
                    onRemove={() => {
                      this.setState({
                        invites: invites.filter((j, jdx) => jdx !== idx)
                      })
                      this.props.resetErrors()
                    }}
                    {...i}
                  />
                )
              })}
            </div>
            <div style={{ width: '100%' }}>
              <div
                className='ds-link'
                onClick={(e) => {
                  const invites = [...this.state.invites]
                  invites.push(this.invite(this.state.defaultContractId))
                  this.setState({ invites })
                  this.props.resetErrors()
                }}
              >
                {'+ ' + t('EMPLOYEE_ADD_ANOTHER')}
              </div>
            </div>
          </Fragment>,
          (allErrors && allErrors.length
            ? (
              <div className='ds-ea-errors-section'>
                {errorUtil.uniqueTypes(allErrors).map((e) => <Err key={e.type} type={e.type} />)}
              </div>
            )
            : null)
        ].filter(Boolean)}
      />
    )
  }
}

export default connect(EmployeeAdd)
