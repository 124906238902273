import store from '../store'
import isLoading from './is-loading'
import addError from './add-error'
import requestCycleCreate from '../request/cycle-create'
import requestCycleUpdate from '../request/cycle-update'
import loadWorkspaceDetail from '@app/ac/load-workspace-detail'
import moment from 'moment'

export default (data, cycleId) => {
  return async (dispatch) => {
    const { auth, workspaceId, workspaces } = store.getState()
    if (!workspaceId || !data || !workspaces) return

    const ws = workspaces?.find(w => w.id === workspaceId)
    const supportedCustomAttribs = ws?.shiftAttributes || []

    dispatch(isLoading('save-cycle'))
    let result = null

    const getCleanedUpShift = (s) => {
      return Object.assign({}, s, { customAttributes: (s?.customAttributes || []).filter(ca => supportedCustomAttribs.some(sca => sca.id === ca.attributeId)) })
    }

    if (cycleId) {
      // if 'cycleId' is set, we're modifying the existing cycle
      result = await requestCycleUpdate({
        id: cycleId,
        workspace: workspaceId,
        data: {
          title: data.title,
          startDate: moment(data.startDate).format('YYYY-MM-DD'),
          length: parseInt(data.length),
          shifts: data.shifts?.length ? data.shifts.map(s => getCleanedUpShift(s)) : []
        }
      }, auth)
    } else {
      // otherwise, we're just creating a new cycle
      result = await requestCycleCreate({
        workspace: workspaceId,
        data: {
          title: data.title,
          startDate: moment(data.startDate).format('YYYY-MM-DD'),
          length: parseInt(data.length),
          shifts: data.shifts.filter(s => s.start < (data.length * 1440)).map(s => getCleanedUpShift(s)) || []
        }
      }, auth)
    }

    if (!result || result.error) {
      if (result) dispatch(addError(result.error))
    }

    await dispatch(loadWorkspaceDetail(workspaceId))

    dispatch(isLoading('save-cycle', true))
    return result
  }
}
