import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'

import setEmployees from '@app/action/set-employees'
import { withPlugin } from '@core/hoc/withPlugin'
import {
  loadEmployeeDetail,
  loadExternalEmployees,
  loadWorkspaceDetail,
  setModal,
  setSidebar,
  setCalendar,
  loadExportToken,
  updateEmployeeCard,
  updateContract,
  loadEmployees
} from '@app/ac'
const mapStateToProps = ({
  auth,
  loading,
  employees,
  exportToken,
  employeeDetail,
  positions,
  workspaces,
  workspaceId,
  calendar,
  me,
  errors,
  organizationId
}) => {
  const ws =
    workspaces && workspaceId
      ? workspaces.find((ws) => ws.id === workspaceId)
      : null
  return {
    auth,
    calendar,
    employees,
    employeeDetail,
    positions,
    exportToken,
    loading,
    me,
    errors: errors.filter((e) => e.request === 'user-update'),
    isLoading:
      loading.includes('employee-detail') || loading.includes('workspaces'),
    workspaceId,
    workspace: ws,
    organizationId
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadEmployeeDetail,
      loadExternalEmployees,
      loadEmployees,
      loadWorkspaceDetail,
      setModal,
      setSidebar,
      setCalendar,
      loadExportToken,
      updateContract,
      updateEmployeeCard,
      setEmployees
    }, dispatch)
}

export default (component) => {
  return compose(
    withPlugin,
    connect(mapStateToProps, mapDispatchToProps)
  )(component)
}
