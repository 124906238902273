"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePluginDeactivationModalOverride = void 0;
const ui_1 = require("@app/component/ui");
const util_1 = require("@app/util");
const store_1 = require("@app/store");
const ac_1 = require("@app/ac");
const maybe_unwrap_1 = require("@app/util/apollo/maybe-unwrap");
const modalOverrideMap = new Map([
    ['chat', {
            modal: 'confirm',
            data: (togglePlugin) => ({
                title: (0, util_1.t)('PLUGIN_DEACTIVATE'),
                subtitle: (0, maybe_unwrap_1.unwrapMaybe)((0, util_1.t)('PLUGIN_DEACTIVATE_MESSAGE.chat')),
                type: ui_1.Alert.TYPES.WARNING,
                confirmLabel: (0, util_1.t)('CONFIRM_DEACTIVATION'),
                cancelLabel: (0, maybe_unwrap_1.unwrapMaybe)((0, util_1.t)('CLOSE')),
                onConfirm: () => __awaiter(void 0, void 0, void 0, function* () { return togglePlugin(); }),
                onCancel: () => { },
                buttonColor: 'red',
            }),
        }],
]);
const usePluginDeactivationModalOverride = () => {
    const dispatch = (0, store_1.useRootDispatch)();
    return (id, togglePlugin) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const modalOverride = modalOverrideMap.get(id);
        if (modalOverride) {
            yield dispatch((0, ac_1.setModal)(null));
            yield dispatch((0, ac_1.setModal)(modalOverride.modal, (_a = modalOverride.data) === null || _a === void 0 ? void 0 : _a.call(modalOverride, togglePlugin)));
            return;
        }
        return togglePlugin();
    });
};
exports.usePluginDeactivationModalOverride = usePluginDeactivationModalOverride;
