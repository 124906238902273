import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import './style.scss'
import { Tooltip } from '@ui'

const TYPES = Object.freeze({
  HEADLINE1: 'headline1',
  HEADLINE2: 'headline2',
  HEADLINE3: 'headline3',
  PARAGRAPH: 'paragraph',
  HEADER: 'header',
  BODY_LARGE: 'body-large',
  BODY_MEDIUM: 'body-medium',
  BODY_SMALL: 'body-small',
  BODY_SMALLEST: 'body-smallest'
})

const COLORS = Object.freeze({
  DEFAULT: 'default',
  PRIMARY: 'primary',
  BRIGHT: 'bright',
  DANGER: 'danger',
  WARNING: 'warning',
  SUCCESS: 'success',
  WHITE: 'white',
  BLACK: 'black',
  GRAY: 'gray',
  GRAY_LIGHT: 'gray-light',
  DARK_BLUE: 'dark-blue',
  INHERIT: 'inherit'
})

const WEIGHTS = Object.freeze({
  BOLD: 'bold',
  MEDIUM: 'medium',
  SEMI_MEDIUM: 'semi-medium',
  THIN: 'thin'
})

const ALIGN = Object.freeze({
  CENTER: 'align-center',
  LEFT: 'align-left',
  RIGHT: 'align-right'
})

export const Text = ({
  type,
  color,
  weight,
  children,
  style,
  className,
  nowrap,
  text,
  align,
  ellipsis,
  testid
}) => {
  const textComponent = (
    <span
      className={classNames(
        'ds-text',
        type,
        color,
        weight,
        className,
        align,
        {
          nowrap: nowrap,
          ellipsis: ellipsis
        }
      )}
      style={style}
      data-testid={testid}
    >
      {text || children}
    </span>
  )
  if (ellipsis) {
    return (
      <Tooltip
        anchor={textComponent}
        className='text-tooltip'
        text={text}
        position={Tooltip.POSITIONS.BOTTOM}
      />
    )
  } else {
    return textComponent
  }
}

Text.propTypes = {
  type: PropTypes.oneOf(Object.values(TYPES)),
  color: PropTypes.oneOf(Object.values(COLORS)),
  weight: PropTypes.oneOf(Object.values(WEIGHTS)),
  align: PropTypes.oneOf(Object.values(ALIGN)),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nowrap: PropTypes.bool,
  ellipsis: PropTypes.bool,
  testid: PropTypes.string
}

Text.defaultProps = {
  type: TYPES.PARAGRAPH,
  color: COLORS.DEFAULT,
  weight: WEIGHTS.SEMI_MEDIUM,
  align: null,
  className: null,
  style: null,
  nowrap: false,
  ellipsis: false,
  text: '',
  children: null,
  testid: undefined
}

Text.TYPES = TYPES
Text.COLORS = COLORS
Text.WEIGHTS = WEIGHTS
Text.ALIGN = ALIGN
